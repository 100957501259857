import React, { useEffect, useState } from "react";
import { injectModels } from "../redux/injectModels";
import { Routes } from "../constants";
import { Link } from "react-router-dom";
import { useAccount } from "wagmi";
import Web3 from "web3";
import * as CONTRACTS from "../Contract";
import { routes } from "../constants/routes";
import { toast } from "react-toastify";

function Dashboard(props) {
  const [usersCount, setUsersCount] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [packages, setPackages] = useState("");

  const ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;

  const { address } = useAccount({
    onConnect({ address, connector, isReconnected }) {},
    onDisconnect() {
      window.location.reload();
      console.log("Disconnected");
    },
  });

  useEffect(() => {
    getContarctData();
    getPackage();
  }, []);

  const getContarctData = async () => {
    try {
      props.application.setLoading(true);
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);

      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

      const details = await contract.methods.getAnalytics().call();
      setUsersCount(details[0]);
      const fee = details && details[1][0];
      const finalFee = web3.utils.fromWei(fee, "ether");
      const price = details && details[1][1];
      const finalPrice = web3.utils.fromWei(price, "ether");

      const totalRevenue = Number(finalFee) + Number(finalPrice);
      setRevenue(totalRevenue);
      props.application.setLoading(false);
    } catch (err) {
      console.log(err, "this is error");
      props.application.setLoading(false);
    }
  };

  const getPackage = async () => {
    try {
      props.application.setLoading(true);
      if (address) {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

        const web3 = new Web3(provider);

        const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

        // const receiptGetPackages = await contract.methods.getPackages().call();
        const receiptGetPackages = await contract.methods
          .getPackagesWithJoiningDetails(address)
          .call();
        setPackages(
          receiptGetPackages.packages_.filter((value) => value[0] === true)
            .length
        );
        props.application.setLoading(false);
      } else {
        props.application.setLoading(false);
        toast.dismiss();
        toast.error("Please connect with any wallet");
      }
    } catch (err) {
      props.application.setLoading(false);

      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            {/* <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li> */}
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
        </nav>
      </div>
      <section className="section dashboard">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-xxl-3 col-md-6 ">
                <Link to={Routes.USER}>
                  <div className="card info-card sales-card card-pointer brdr-c hgty">
                    <div className="card-body">
                      <h5 className="card-title">
                        Registered Users <span>| Total</span>
                      </h5>
                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="fa-solid fa-user-group" />
                        </div>
                        <div className="ps-3">
                          <h6>{usersCount}</h6>
                        </div>
                      </div>
                      {/* <p className="hour mt-2">Last 24 hours</p> */}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xxl-3 col-md-6 ">
                <Link to={routes.ADMIN_WALLET}>
                  <div className="card info-card customers-card card-pointer brdr-c hgty">
                    <div className="card-body">
                      <h5 className="card-title">
                        Business <span>| Total</span>
                      </h5>
                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          {/* <i className="fa-solid fa-user-group" /> */}
                          <i className="fa-solid fa-dollar-sign" />
                        </div>
                        <div className="ps-3">
                          <h6>{revenue}</h6>
                          {/* <span className="text-danger small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">decrease</span> */}
                        </div>
                      </div>
                      {/* <p className="hour mt-2">Last 24 hours</p> */}
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xxl-3 col-md-6">
                <Link to={Routes.PACKAGES}>
                  <div className="card info-card wallet-card card-pointer brdr-c hgty">
                    <div className="card-body">
                      <h5 className="card-title">
                        Available Packages <span>| Total</span>
                      </h5>
                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          {/* <i className="fa-solid fa-user-group" /> */}
                          <i className="fa-solid fa-ticket"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{packages}</h6>
                        </div>
                      </div>
                      {/* <p className="hour mt-2">Last 24 hours</p> */}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default injectModels(["admin", "application"])(Dashboard);
