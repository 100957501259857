import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { injectModels } from "../../redux/injectModels";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";

// Check if new password contains at least one uppercase letter, lowercase letter, number, and symbol
const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

function ChangePassword(props) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldError, setOldError] = useState("");
  const [newError, setNewError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [error, setError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [oldPasswordType, setOldPasswordType] = useState("password");
  const [showOldPassword,setShowOldPassword] = useState(false);
  const [showNewPassword,setShowNewPassword] = useState(false);
  const [showRePassword,setShowRePassword] = useState(false);

  const toggleOldPassword = (e) => {
    setShowOldPassword((prevShowPassword) => !prevShowPassword);
  };

  const togglePassword = (e) => {
    setShowNewPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPassword = (e) => {
    setShowRePassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    // handleShow();
  }, []);

  const handleOldPasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setOldError("Old Password is required!");
    } else {
      setOldError("");
    }
    setOldPassword(val);
  };

  const handleNewPasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setNewError("New Password is required");
    } else if (!passwordRegex.test(newPassword)) {
      setNewError(
        "New Password must contain at least one uppercase letter, one lowercase letter, one number, one symbol and minimum length is 8!"
      );
    } else {
      setNewError("");
    }
    setNewPassword(val);
  };

  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setConfirmError("Confirm Password is required!");
    } else {
      setConfirmError("");
    }
    setConfirmPassword(val);
  };

  const handleSubmit = async (e) => { 
    e.preventDefault();

    let valid = true;

    if (oldPassword.trim() === "") {
      setOldError("Old Password is required!");
      valid = false;
      return;
    } else {
      setOldError("");
    }
    if (newPassword.trim() === "") {
      setNewError("New Password is required!");
      valid = false;
      return;
    } else {
      if (!passwordRegex.test(newPassword)) {
        setNewError(
          "New Password must contain at least one uppercase letter, one lowercase letter, one number, one symbol and minimum length is 8!"
        );
        return;
      }
      setNewError("");
    }
    if (confirmPassword.trim() === "") {
      setConfirmError("Confirm Password is required!");
      valid = false;
      return;
    } else {
      setConfirmError("");
    }

    if (oldPassword.trim() === newPassword.trim()) {
      setError("New Password cannot be same as Old Password");
      return;
    }

    // Check if new password is at least 8 characters long
    if (newPassword.length < 8) {
      setNewError("New Password must be at least 8 characters long!");
      valid = false;
      return;
    }

    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      setConfirmError("New Password and Confirm Password did not match!");
      valid = false;
      return;
    }

    if (!valid) {
      setError("Please fill all fields!");
      return;
    } else {
      setError("");
      try { 
        props.application.setLoading(true);
        const data = {
          current_password: oldPassword,
          new_password: newPassword.trim(),
        };
        const response = await props.admin.changePassword(data);
        if (response.status === 200) {
          props.application.setLoading(false);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          // setError(response.data.message)
          setOldPassword("");
          setConfirmPassword("");
          setNewPassword("");
        } else {
          setError(response.data.message)
          props.application.setLoading(false);
        }
      } catch (err) {
        console.log("are nahi yaha aayi error");
        props.application.setLoading(false);
        setError("Please enter the correct Old Password")
      }
    }
  };

  return (
    <React.Fragment>
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={routes.DASHBOARD}>Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Change Password</li>
          </ol>
        </nav>
      </div>
      <div className="page-content page-container" id="page-content">
        <div className="padding">
          <div className="row  d-flex justify-content-center">
            <div className="col-xl-4 col-lg-6 col-md-6  mt-1">
              <div className="card user-card-full ter for">
                <form onSubmit={handleSubmit}>
                  <label htmlFor="yourUsername" className="form-label">
                    Old Password<span className="aestrick">*</span>
                  </label>
                  <div className="input-group change-pass">
                    <input
                      id="oldpassword"
                      type={showOldPassword ? "text" : "password"}
                      onChange={handleOldPasswordChange}
                      value={oldPassword}
                      name="password"
                      maxLength={80}
                      className="form-control mb-3"
                      placeholder="Old Password*"
                    />
                    <div className="input-group-btn">
                      <span
                        className="btn btn-outline-primary custom-button-colour"
                        onClick={toggleOldPassword}
                      >
                        {showOldPassword ? (
                            <i className="fa-solid fa-eye"></i>
                          ) : (
                            <i className="fa-solid fa-eye-slash"></i>
                          )}
                      </span>
                    </div>
                  </div>
                  {oldError && <div className="error">{oldError}</div>}
                  <label htmlFor="yourUsername" className="form-label">
                    New Password<span className="aestrick">*</span>
                  </label>
                  <div className="input-group change-pass">
                    <input
                      id="newpassword"
                      type={showNewPassword ? "text" : "password"}
                      onChange={handleNewPasswordChange}
                      name="password"
                      value={newPassword}
                      maxLength={80}
                      className="form-control mb-3 "
                      placeholder="New Password*"
                    />
                    <div className="input-group-btn">
                      <span
                        className="btn btn-outline-primary custom-button-colour"
                        onClick={togglePassword}
                      >
                       {showNewPassword ? (
                            <i className="fa-solid fa-eye"></i>
                          ) : (
                            <i className="fa-solid fa-eye-slash"></i>
                          )}
                      </span>
                    </div>
                  </div>
                  {newError && <div className="error">{newError}</div>}
                  <label htmlFor="yourUsername" className="form-label">
                    Confirm Password<span className="aestrick">*</span>
                  </label>
                  <div className="input-group change-pass">
                    <input
                      id="confpassword"
                      type={showRePassword ? "text" : "password"}
                      onChange={handleConfirmPasswordChange}
                      name="password"
                      value={confirmPassword}
                      maxLength={80}
                      className="form-control mb-3"
                      placeholder="Confirm Password*"
                    />
                    <div className="input-group-btn">
                      <span
                        className="btn btn-outline-primary custom-button-colour"
                        onClick={toggleConfirmPassword}
                      >
                        {showRePassword ? (
                            <i className="fa-solid fa-eye"></i>
                          ) : (
                            <i className="fa-solid fa-eye-slash"></i>
                          )}
                      </span>
                    </div>
                  </div>
                  {confirmError && <div className="error">{confirmError}</div>}
                  <div className="btn-sbm">
                    <button
                      type="submit"
                      className="btn btn-primary custom-button-colour"
                    >
                      Submit
                    </button>
                  </div>
                </form>
                {error && (
                  <div className="col-xs-12 col-md-12 col-lg-12 mt-1">
                    <div className="alert alert-danger">{error}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default injectModels(["admin", "application"])(ChangePassword);
