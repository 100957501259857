import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import { toast } from "react-toastify";
import MyLoader from "../loader/LoaderFile";
import { injectModels } from "../../redux/injectModels";
import { coverImageTypes } from "../../constants";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

function EditProfile(props) {
  let navigate = useNavigate();
  const [loader, setLoader] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [firstNameError , setFirstNameError] = useState("");
  const [lastNameError , setLastNameError] = useState("");
  const [image, setImage] = useState("");
  const avatarFileRef = React.useRef();
  const [error, setError] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      props.application.setLoading(true);
      try {
        const res = await props.admin.getAdmin();
        if (res.admin) {
          setImage(res.admin.avatar ? res.admin.avatar : "");
          setFirstName(res.admin.firstName);
          setLastName(res.admin.lastName);
          props.application.setLoading(false);
        } else {
          props.application.setLoading(false);
        }
      } catch (error) {
        props.application.setLoading(false);
      }
    } catch (error) {
      props.application.setLoading(false);
      toast.error("Somthing went wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleFirstName = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setFirstNameError("First Name is required!");
    } else {
      setFirstNameError("");
    }
    setFirstName(val);
  };

  const handleLastName = (e) => {
    e.preventDefault();
    const val = e.target.value;
    setLastName(val);
  };

  async function handleImageChange(e) {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (!file.type.startsWith('image/')) {
      toast.error('Please select a valid image file', {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
  
    const data = new FormData();
    data.append("fileInput", file);
    try {
      setIsUploading(true);
      const response = await props.admin.uploadAdminImage(data);
      if (response.status === 200) {
        setImage(response.data && response.data.file.url);
        setIsUploading(false);
        props.application.setLoading(false);
      } else {
        props.application.setLoading(false);
      }
    } catch (error) {
      toast(error.toString());
      props.application.setLoading(false);
    }
  }

  const updateProfile = async (e) => {
    e.preventDefault();

    let valid = true;

    if (firstname.trim() === "") {
      setFirstNameError("First Name is required!");
      valid = false;
      return;
    } else {
      setFirstNameError("");
    }

    if (!valid) {
      setError("Please fill all fields!");
      return;
    } else {
      setError("");

    try {
      const data = {
        firstName: firstname && firstname.trim(),
        lastName: lastname ? lastname.trim() : "",
        avatar: image && image,
      };
      const res = await props.admin.updateAdminProfile(data);

      if (res.data.success === true) {
        props.application.setLoading(false);
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        // setImageLoading(false);
        navigate(`/profile`);
      } else {
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_CENTER,
        });
        props.application.setLoading(false);
      }
    } catch (error) {
      console.log(error);
      props.application.setLoading(false);
      toast.error("Could not update details", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }
  };
  return (
    <React.Fragment>
      <div className="profile-icon-ty">
        <div className="pagetitle">
          {/* <h1>Profile</h1> */}
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={routes.DASHBOARD}>Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={routes.PROFILE}>Profile</Link>
              </li>
              <li className="breadcrumb-item active">Edit Profile</li>
            </ol>
          </nav>
        </div>

        <Link
          className="fa fa-arrow-left"
          aria-hidden="true"
          to={routes.PROFILE}
        >
          {" "}
        </Link>
      </div>

      <div className="page-content page-container" id="page-content">
        <div className="padding">
          <div className="row  d-flex justify-content-center">
            <div className="col-xl-12 col-md-12 mt-1">
              <div className="">
                <div className="row m-l-0 m-r-0">
                  <div className="col-md-6 col-sm-12 bg-c-lite-green user-profile">
                    <div className="card-block text-center text-white">
                      <div className="m-b-25">
                        <input
                          hidden
                          ref={avatarFileRef}
                          type="file"
                          name="profileImage"
                          onChange={handleImageChange}
                          accept={coverImageTypes.mimeTypes.join(", ")}
                        />
                        <div
                          className="box_content asset-uploader mb-3"
                          onClick={(e) => {
                            e.preventDefault();
                            avatarFileRef.current.click();
                          }}
                        >
                          {isUploading ? (
                            <CircularProgress />
                          ) : (
                            <img className="img-radius" src={image} alt="" />
                          )}
                          <button className="btn btn-primary custom-button-colour">
                            {" "}
                            Select Image<span className="aestrick">*</span>{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="card user-card-full ter p-5">
                      <form>
                        <div className="mb-3">
                          <label htmlFor="firstname" className="form-label">
                            First Name<span className="aestrick">*</span>
                          </label>    
                          <input
                            id="firstname"
                            name="firstname"
                            type="text"
                            className="form-control"
                            value={firstname}
                            onChange={handleFirstName}
                          />
                           {firstNameError && <div className="error">{firstNameError}</div>}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="lastname" className="form-label">
                            Last Name
                          </label>
                          <input
                            id="lastname"
                            name="lastname"
                            type="text"
                            className="form-control"
                            value={lastname}
                            onChange={handleLastName}
                          />
                           {lastNameError && <div className="error">{lastNameError}</div>}
                        </div>
                        <div className="btn-sbm">
                          <button
                            type="submit"
                            className="btn btn-primary custom-button-colour"
                            onClick={(e) => updateProfile(e)}
                          >
                            Submit
                            {loader === true ? <MyLoader /> : ""}
                          </button>
                        </div>
                      </form>
                      {error && (
                        <div className="col-xs-12 col-md-12 col-lg-12">
                          <div className="alert alert-danger">{error}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default injectModels(["admin", "application"])(EditProfile);
