import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import { injectModels } from "../../redux/injectModels";
import { useParams } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Web3 from "web3";
import * as CONTRACTS from "../../Contract";
import { Routes } from "../../constants";

function UserProfile(props) {
  const [data, setData] = useState("");
  const [transactions, setTransactions] = useState("");
  const [spotBalance, setSpotBalance] = useState("");
  const [nakaBalance, setNakaBalance] = useState("");
  const [rank, setRank] = useState("");
  const [investment, setInvestment] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [packagesIndex, setPackagesIndex] = useState("");
  const [activePackagePrice, setActivePackagePrice] = useState("");
  const [allPackageList, setAllPackagesList] = useState([]);
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [showTopUpModal1, setShowTopUpModal1] = useState(false);
  const [joiningDate, setJoiningDate] = useState(false);
  const [spotNonWorkingIncome, setSpotNonWorkingIncome] = useState("");
  const [nktNonWorkingIncome, setNktNonWorkingIncome] = useState("");
  const [spotSponsorIncome, setSpotSponsorIncome] = useState("");
  const [nktSponsorIncome, setNktSponsorIncome] = useState("");
  const [spotTeamBuildBonus, setSpotTeamBuildBonus] = useState("");
  const [nktTeamBuildBonus, setNktTeamBuildBonus] = useState("");
  const [spotBoostIncome, setSpotBoostIncome] = useState("");
  const [nktBoostIncome, setNktBoostIncome] = useState("");
  const [spotRoyaltyIncome, setSpotRoyaltyIncome] = useState("");
  const [nktRoyaltyIncome, setNktRoyaltyIncome] = useState("");
  const [totalDirectCount, setTotalDirectCount] = useState("");
  const [totalTeamCount, setTotalTeamCount] = useState("");
  const [prices, setPrices] = useState([]);
  const [todayNonWorkingIncome, setTodayNonWorkingIncome] = useState("");
  const [todaySponsorIncome, setTodaySponsorIncome] = useState("");
  const [todayTeamBuildBonus, setTodayTeamBuildBonus] = useState("");
  const [todayBoostIncome, setTodayBoostIncome] = useState("");
  const [todayRoyaltyIncome, setTodayRoyaltyIncome] = useState("");
  const [web3Instance, setWeb3Instance] = useState("");
  const [withdrawalDetails, setWithdrawalDetails] = useState("");

  const param = useParams();
  const nonWorkingIncome =
    Number(spotNonWorkingIncome) + Number(nktNonWorkingIncome);
  const sponsorIncome = Number(spotSponsorIncome) + Number(nktSponsorIncome);
  const teamBuildBonus = Number(spotTeamBuildBonus) + Number(nktTeamBuildBonus);
  const boostIncome = Number(spotBoostIncome) + Number(nktBoostIncome);
  const royaltyIncome = Number(spotRoyaltyIncome) + Number(nktRoyaltyIncome);
  const ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;

  const getData = async () => {
    props.application.setLoading(true);

    try {
      const res = await props.admin.getUserDetails(param.address);
      if (res.data.data) {
        setData(res.data.data.user);
        props.application.setLoading(false);
      } else {
        props.application.setLoading(false);
      }
    } catch (error) {
      props.application.setLoading(false);
    }
  };

  const getPackageJoiningDetailsList = async () => {
    try {
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);
      setWeb3Instance(web3);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

      const packageJoiningDetails = await contract.methods
        .getPackagesWithJoiningDetails(param.address)
        .call();

      return packageJoiningDetails;
    } catch (err) {
      console.log(err, "err");
      props.application.setLoading(false);
      toast.error("Something went wrong");
    }
  };
  const fetchPackageDetails = async () => {
    try {
      const pd = await getPackageJoiningDetailsList();
      const newPrices = pd[0].map((e) => ({
        packageIndex: e.packageIndex,
        price: e.price,
      }));
      setPrices(newPrices);
    } catch (error) {
      console.error("Error fetching package details:", error);
    }
  };

  useEffect(() => {
    fetchPackageDetails();
  }, []);

  const getPriceByIndex = (index) => {
    const priceObject = prices.find((p) => p.packageIndex === index);
    return priceObject
      ? web3Instance.utils.fromWei(priceObject.price, "ether")
      : 0;
  };

  const getTotalWithdrawal = async () => {
    try {
      props.application.setLoading(true);
      if (param.address) {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
        const web3 = new Web3(provider);
        const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
        const receipt = await contract.methods
          .usersWithdraw(param.address)
          .call();
        setWithdrawalDetails(receipt);
        console.log(receipt.totalSpotWalletWithdraw, "withdrawal details");
        props.application.setLoading(false);
      } else {
        console.log("No adddress found");
        props.application.setLoading(false);
      }
    } catch (err) {
      console.log(err, "err");
      props.application.setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    getUsersData();
    getTotalWithdrawal();
  }, []);

  const handleCopyClick = () => {
    toast.dismiss();
    toast.success("Copied..");
  };

  const handleShowTransactions = () => {
    setShowTopUpModal(true);
    setShowTopUpModal1(false);
  };
  const handleTopUp = () => {
    setShowTopUpModal(false);
    setShowTopUpModal1(true);
  };

  const handleCloseTopUpModal = () => {
    setShowTopUpModal(false);
  };
  const handleCloseTopUpModal1 = () => {
    setShowTopUpModal1(false);
  };

  const convertTimestampToDateTime = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    let hours = date.getUTCHours();
    const minutes = `0${date.getUTCMinutes()}`.slice(-2);
    const seconds = `0${date.getUTCSeconds()}`.slice(-2);

    let period = "AM";
    if (hours >= 12) {
      period = "PM";
      hours -= 12;
    }
    if (hours === 0) {
      hours = 12;
    }

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${period}`;

    return formattedDateTime;
  };
  const getPackageJoiningDetails = async (pIndex) => {
    props.application.setLoading(true);
    try {
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

      const packageJoiningDetails = await contract.methods
        .getPackagesWithJoiningDetails(param.address)
        .call();
      setTokenSymbol(packageJoiningDetails.joiningTokenSymbol_);
      const packages = await packageJoiningDetails.packages_;

      if (packages && packages.length > 0) {
        let selectedPackage;
        packages.forEach((e) => {
          if (e.packageIndex === pIndex) {
            selectedPackage = e;
          }
        });

        if (selectedPackage) {
          const packageprice = web3.utils.fromWei(
            selectedPackage.price.toString(),
            "ether"
          );
          setActivePackagePrice(packageprice);
          props.application.setLoading(false);
        } else {
          console.log("Invalid packages index");
          props.application.setLoading(false);
        }
      } else {
        console.log("packages not fetched");
        props.application.setLoading(false);
      }
    } catch (err) {
      console.log(err, "err");
      props.application.setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const getUsersData = async () => {
    if (param.address) {
      props.application.setLoading(true);

      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
      const userDetails = await contract.methods.getUser(param.address).call();
      const packages = web3.utils.fromWei(userDetails.totalInvestment, "ether");
      setInvestment(packages);
      let pIndex;
      setAllPackagesList(userDetails.joinedPackages);
      userDetails.joinedPackages.forEach((e) => {
        if (e[0] === true) {
          pIndex = e[2];
        }
      });

      const spotIncome = web3?.utils.fromWei(
        userDetails.availableSpotWalletIncome,
        "ether"
      );
      const nakaIncome = web3?.utils.fromWei(
        userDetails.availableNakaWalletIncome,
        "ether"
      );

      setPackagesIndex(pIndex);
      setRank(userDetails && userDetails.rank);
      setSpotBalance(spotIncome);
      setNakaBalance(nakaIncome);
      setTransactions(userDetails && userDetails.withdraws);
      setJoiningDate(userDetails && userDetails.timestamp);
      setSpotNonWorkingIncome(
        userDetails && web3?.utils.fromWei(userDetails[24], "ether")
      );
      setNktNonWorkingIncome(
        userDetails && web3?.utils.fromWei(userDetails[25], "ether")
      );
      setSpotSponsorIncome(
        userDetails && web3?.utils.fromWei(userDetails[22], "ether")
      );
      setNktSponsorIncome(
        userDetails && web3?.utils.fromWei(userDetails[23], "ether")
      );
      setSpotTeamBuildBonus(
        userDetails && web3?.utils.fromWei(userDetails[20], "ether")
      );
      setNktTeamBuildBonus(
        userDetails && web3?.utils.fromWei(userDetails[21], "ether")
      );
      setSpotBoostIncome(
        userDetails && web3?.utils.fromWei(userDetails[18], "ether")
      );
      setNktBoostIncome(
        userDetails && web3?.utils.fromWei(userDetails[19], "ether")
      );
      setSpotRoyaltyIncome(
        userDetails && web3?.utils.fromWei(userDetails[16], "ether")
      );
      setNktRoyaltyIncome(
        userDetails && web3?.utils.fromWei(userDetails[17], "ether")
      );

      try {
        const userAnalytics = await contract.methods
          .getUserAnalytics(param.address)
          .call();
        if (userAnalytics) {
          setTodayNonWorkingIncome(
            web3?.utils.fromWei(userAnalytics.todayNonWorkingIncome, "ether")
          );
          setTodaySponsorIncome(
            web3?.utils.fromWei(userAnalytics.todaySponsorIncome, "ether")
          );
          setTodayTeamBuildBonus(
            web3?.utils.fromWei(userAnalytics.todayTeamBuildBonus, "ether")
          );
          setTodayBoostIncome(
            web3?.utils.fromWei(userAnalytics.todayBoostIncome, "ether")
          );
          setTodayRoyaltyIncome(
            web3?.utils.fromWei(userAnalytics.todayRoyaltyIncome, "ether")
          );
          setTotalTeamCount(userAnalytics.todayTeam);
          setTotalDirectCount(userAnalytics.todayDirectTeam);
        }
        props.application.setLoading(false);
      } catch (err) {
        props.application.setLoading(false);

        console.log(err);
      }

      await getPackageJoiningDetails(pIndex);
      props.application.setLoading(false);
    } else {
      props.application.setLoading(false);
      toast.error("Please connect your wallet to view the details.");
    }
  };

  return (
    <React.Fragment>
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={routes.DASHBOARD}>Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Profile</li>
          </ol>
        </nav>
      </div>
      <div className="page-content page-container" id="page-content">
        <div className="padding">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-12 col-md-12 mt-0">
              <div className="row m-l-0 m-r-0">
                <div className="col-sm-4 ">
                  <div className="bg-c-lite-green user-profile">
                    <div className="usr-tyu">
                      <img
                        src={
                          data.avatar
                            ? `${data.avatar.toString()}`
                            : "/assets/img/default-image.png"
                        }
                        className="img-radius"
                        alt=""
                      />
                    </div>
                    {/* <h6 className="f-w-600 user-name-heading">
                      {data.firstName}
                    </h6> */}
                    <p className="user-name-pargraph">
                      {data.firstName} {data.lastName}
                    </p>
                    <i className="mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16" />
                    <div className="info">
                      <h6 className="user-information">Information </h6>

                      <div className="row pd-ty">
                        <div className="col-sm-6 mt-4 mb-4">
                          <div className="f-name">
                            <p className=" f-w-600">First Name</p>
                            <h6 className="text-muted f-w-400">
                              {data.firstName}
                            </h6>
                          </div>
                        </div>
                        <div className="col-sm-6 mt-4 mb-4">
                          <div className="f-name">
                            <p className=" f-w-600">Last Name</p>
                            <h6 className="text-muted f-w-400">
                              {data.lastName}
                            </h6>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-4">
                          <div className="f-name">
                            <p className=" f-w-600">User Id</p>
                            <h6 className="text-muted f-w-400">
                              {data.userId}
                            </h6>
                          </div>
                        </div>
                        <div className="col-sm-6  mb-4">
                          <div className="f-name">
                            <p className=" f-w-600">Uplined Id</p>
                            <h6 className="text-muted f-w-400">
                              {data.uplineId}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className=" b-b-default f-w-600 user-information">
                      Wallet
                    </h6>
                    <div className="row mt-4 pd-ty">
                      <div className="col-md-12 mb-4">
                        <div className="addres-w">
                          <p className=" f-w-600">Wallet Address</p>
                          <CopyToClipboard
                            text={data.walletAddress}
                            onCopy={handleCopyClick}
                          >
                            <h6 className="text-muted f-w-400">
                              {data.walletAddress}{" "}
                              <i className="fa-regular fa-copy"></i>
                            </h6>
                          </CopyToClipboard>

                          {/* <a href="#"> <h6 className="text-muted f-w-400">{data.walletAddress} <i class="fa-regular fa-copy"></i></h6> </a> */}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="addres-w">
                          <p className=" f-w-600">Upline Address</p>

                          {/* {" "}
                            <h6 className="text-muted f-w-400">
                              {data.uplineAddress}{" "}
                              <i class="fa-regular fa-copy"></i>
                            </h6>{" "}
                         */}
                          <CopyToClipboard
                            text={data.uplineAddress}
                            onCopy={handleCopyClick}
                          >
                            <h6 className="text-muted f-w-400">
                              {data.uplineAddress}{" "}
                              <i className="fa-regular fa-copy"></i>
                            </h6>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                    <h6 className=" b-b-default f-w-600 user-information">
                      Network
                    </h6>

                    <div className="networklink">
                      <Link to={Routes.USER_NETWORK + `/${param.address}`}>
                        view network..
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="card user-card-full">
                    <h6 className=" b-b-default f-w-600 user-information">
                      Balance
                    </h6>
                    <div className="row mt-4 pd-ty">
                      <div className="col-md-6 mb-4">
                        <div className="addres-w">
                          <p className=" f-w-600">Spot Balance</p>
                          <h6 className="text-muted f-w-400">
                            {Number(spotBalance).toFixed(4)} {tokenSymbol}
                          </h6>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="addres-w">
                          <p className=" f-w-600">Naka Balance</p>
                          <h6 className="text-muted f-w-400">
                            {Number(nakaBalance).toFixed(4)} {tokenSymbol}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <h6 className=" b-b-default f-w-600 user-information">
                      Additional Details
                    </h6>
                    <div className="row mt-4 pd-ty">
                      <div className="col-md-6 mb-4">
                        <p className=" f-w-600">Date of Joining</p>
                        <h6 className="text-muted f-w-400">
                          {convertTimestampToDateTime(joiningDate)}
                        </h6>
                      </div>
                      <div className="col-md-6  ">
                        <p className=" f-w-600">Rank</p>
                        <h6 className="text-muted f-w-400">
                          {rank === "N/A" ? (
                            "No rank achieved yet"
                          ) : (
                            <>{rank}</>
                          )}
                        </h6>
                      </div>
                      <div className="col-md-6 mb-4">
                        <p className=" f-w-600">Total Team Count(Today)</p>
                        <h6 className="text-muted f-w-400">{totalTeamCount}</h6>
                      </div>
                      <div className="col-md-6 mb-4">
                        <p className=" f-w-600">Direct Team Count(Today)</p>
                        <h6 className="text-muted f-w-400">
                          {totalDirectCount}
                        </h6>
                      </div>

                      <div className="col-md-6 mb-4  ">
                        <p className=" f-w-600">Package Details</p>
                        <h6 className="text-muted f-w-400">
                          {/* <button
                            className="wallet-balance"
                          >
                           Details
                          </button> */}
                          Total Investment : {investment} {tokenSymbol}
                        </h6>
                      </div>
                      <div className="row pd-ty">
                        <div className="col-md-6 mb-4">
                          <p className=" f-w-600">Transactions</p>
                          <h6 className="text-muted f-w-400">
                            <button
                              className="wallet-balance"
                              onClick={handleShowTransactions}
                            >
                              History
                            </button>
                          </h6>
                        </div>
                        <div className="col-md-6 mb-4">
                          <p className=" f-w-600">Top-Up</p>
                          <h6 className="text-muted f-w-400">
                            <button
                              className="wallet-balance"
                              onClick={handleTopUp}
                            >
                              History
                            </button>
                          </h6>
                        </div>

                        <div className="tbing-transaction-ad">
                          <h6>Report & Analytics </h6>
                          <div className="table-responsive">
                            <table>
                              <thead>
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col">Today(USDT)</th>
                                  <th scope="col">Total(USDT)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Non-Working Income:</td>
                                  <td>
                                    {Number(todayNonWorkingIncome).toFixed(4)}
                                  </td>
                                  <td>{nonWorkingIncome.toFixed(4)}</td>
                                </tr>
                                <tr>
                                  <td>Sponsor Income:</td>
                                  <td>
                                    {Number(todaySponsorIncome).toFixed(4)}
                                  </td>
                                  <td>{sponsorIncome.toFixed(4)}</td>
                                </tr>

                                <tr>
                                  <td>Team Build Bonus:</td>
                                  <td>
                                    {Number(todayTeamBuildBonus).toFixed(4)}
                                  </td>
                                  <td>{teamBuildBonus.toFixed(4)}</td>
                                </tr>

                                <tr>
                                  <td>Boost Income:</td>
                                  <td>{Number(todayBoostIncome).toFixed(4)}</td>
                                  <td>{boostIncome.toFixed(4)}</td>
                                </tr>

                                <tr>
                                  <td>Royalty income:</td>
                                  <td>
                                    {Number(todayRoyaltyIncome).toFixed(4)}
                                  </td>
                                  <td>{royaltyIncome.toFixed(4)}</td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="mt-3 reportlink">
                              <Link
                                to={Routes.USER_REPORT + `/${param.address}`}
                              >
                                More details..
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showTopUpModal}
        onHide={handleCloseTopUpModal}
        backdrop="static"
      >
        <div className="withdraw">
          <Modal.Header closeButton>
            <Modal.Title>Transaction History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table>
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Wallet</th>

                  <th scope="col">Amount</th>
                  <th scope="col">Time</th>
                </tr>
              </thead>
              <tbody>
                {transactions && transactions.length > 0 ? (
                  <>
                    {transactions.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {item.isFromNakaWallet === true
                              ? " NaKa Wallet"
                              : "Spot Wallet"}
                          </td>
                          <td>
                            {Number(
                              web3Instance.utils.fromWei(item.amount, "ether")
                            ).toFixed(4)}
                          </td>
                          <td>{convertTimestampToDateTime(item.timestamp)}</td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <div>
                    <span>No Transaction history...</span>
                  </div>
                )}
              </tbody>
            </table>
            <div className="balance-details">
              <h6>
                Total Withdrawal <span>(Including old withdrawals)</span>
              </h6>
              <div className="withamount">
                <p>
                  Naka Wallet :{" "}
                  <span>
                    {Number(
                      withdrawalDetails.totalNakaWalletWithdraw &&
                        web3Instance?.utils.fromWei(
                          withdrawalDetails.totalNakaWalletWithdraw,
                          "ether"
                        )
                    ).toFixed(4)}{" "}
                    USDT
                  </span>
                </p>
                <p>
                  Spot Wallet :{" "}
                  <span>
                    {Number(
                      withdrawalDetails.totalSpotWalletWithdraw &&
                        web3Instance?.utils.fromWei(
                          withdrawalDetails.totalSpotWalletWithdraw,
                          "ether"
                        )
                    ).toFixed(4)}{" "}
                    USDT
                  </span>
                </p>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        show={showTopUpModal1}
        onHide={handleCloseTopUpModal1}
        backdrop="static"
      >
        <div className="withdraw">
          <Modal.Header closeButton>
            <Modal.Title>Top-Up History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table>
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Status</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Time</th>
                </tr>
              </thead>
              <tbody>
                {allPackageList && allPackageList.length > 0 ? (
                  <>
                    {allPackageList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {item.isActive === true ? "Active" : "Inactive"}
                          </td>
                          <td>
                            {getPriceByIndex(item.packageIndex)} {tokenSymbol}
                          </td>
                          <td>{convertTimestampToDateTime(item.timestamp)}</td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <div>
                    <span>No Top-Up history...</span>
                  </div>
                )}
              </tbody>
            </table>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
}
export default injectModels(["admin", "application"])(UserProfile);
