import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Web3 from "web3";
import { injectModels } from "../../redux/injectModels";
import { useAccount, useContractWrite, usePrepareContractWrite } from "wagmi";
import * as CONTRACTS from "../../Contract";

const TeamBuildBonus = (props) => {
  const [walletAddress, setWalletAddress] = useState("");
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [web3Instance, setweb3Instance] = useState();
  const [sponsorHistory, setSponsorHistory] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState("");
  const [hasFilteredRows, setHasFilteredRows] = useState(true);

  const param = useParams();
  const ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;
  const { address } = useAccount({
    onConnect({ address }) {
      setWalletAddress(address);
    },
    onDisconnect() {
      window.location.reload();
    },
  });

  useEffect(() => {
    if (param.address) {
      getSponsorIncomeHistory();
    }
  }, [param.address]);

  function datestampToUTC(timestamp) {
    const date = new Date(parseInt(timestamp) * 1000);
    date.setDate(date.getDate() + 1);
    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);

    const formattedDateTime = `${year}-${month}-${day} `;

    return formattedDateTime;
  }
  function timestampToUTC(timestamp) {
    const date = new Date(parseInt(timestamp) * 1000);
    date.setDate(date.getDate() + 1);
    let hours = date.getUTCHours();
    const minutes = `0${date.getUTCMinutes()}`.slice(-2);
    const seconds = `0${date.getUTCSeconds()}`.slice(-2);

    let period = "AM";
    if (hours >= 12) {
      period = "PM";
      hours -= 12;
    }
    if (hours === 0) {
      hours = 12;
    }

    const formattedDateTime = `${hours}:${minutes}:${seconds} ${period}`;

    return formattedDateTime;
  }

  function transformData(inputData) {
    props.application.setLoading(true);
    const outputData = [];
    const provider = window.ethereum
      ? window.ethereum
      : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

    const web3 = new Web3(provider);
    inputData.forEach((item) => {
      const nakaWalletData = {
        sno: outputData.length + 1,
        amount: `${web3?.utils.fromWei(
          `${item.nakaWalletIncome}`,
          "ether"
        )} USDT`,
        date: datestampToUTC(item.timestamp),
        time: timestampToUTC(item.timestamp),
        wallet: "Naka wallet",
        from: item.from,
      };
      outputData.push(nakaWalletData);

      const spotWalletData = {
        sno: outputData.length + 1,
        amount: `${web3?.utils.fromWei(
          `${item.spotWalletIcome}`,
          "ether"
        )} USDT`,
        date: datestampToUTC(item.timestamp),
        time: timestampToUTC(item.timestamp),
        wallet: "Spot wallet",
        from: item.from,
      };
      outputData.push(spotWalletData);
    });
    props.application.setLoading(false);
    return outputData;
  }

  const getSponsorIncomeHistory = async () => {
    try {
      props.application.setLoading(true);

      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);
      setweb3Instance(web3);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

      const sponsorIncomeLength = await contract.methods
        .usersTeamBuildBonusIncomes(param.address)
        .call();

      const lengthSponsor = sponsorIncomeLength.totalTeamBuildBonusIncomes;
      let incomeHistoryDetails = [];
      for (let index = 0; index < lengthSponsor; index++) {
        const historyElement = await contract.methods
          .getIncomeHistory(param.address, index, 1)
          .call();
        incomeHistoryDetails = [
          {
            from: historyElement.from,
            nakaWalletIncome: historyElement.nakaWalletIncome,
            spotWalletIcome: historyElement.spotWalletIcome,
            timestamp: historyElement.timestamp,
          },
          ...incomeHistoryDetails,
        ];
      }

      const sponsorHistory = transformData(incomeHistoryDetails);

      setSponsorHistory(sponsorHistory);
      props.application.setLoading(false);
    } catch (err) {
      console.log(err, "err");
      props.application.setLoading(false);
    }
  };

  const filterByWallet = (row) => {
    if (selectedWallet === "") return true;
    return row.wallet === selectedWallet;
  };

  const filterByDateRange = (row) => {
    if (!startDate || !endDate) return true;

    const rowDate = new Date(row.date);
    const startUTC = new Date(startDate.toISOString().split("T")[0]);
    const endUTC = new Date(endDate.toISOString().split("T")[0]);

    return rowDate >= startUTC && rowDate <= endUTC;
  };

  const filterOutCurrentDate = (row) => {
    const currentDate = new Date();
    const rowDate = new Date(row.date);
    return (
      rowDate.getUTCFullYear() !== currentDate.getUTCFullYear() ||
      rowDate.getUTCMonth() !== currentDate.getUTCMonth() ||
      rowDate.getUTCDate() !== currentDate.getUTCDate()
    );
  };

  const filteredRows = sponsorHistory
    .reverse()
    .filter(
      (row) =>
        filterByDateRange(row) &&
        filterByWallet(row) &&
        filterOutCurrentDate(row)
    );

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const columns = [
    { id: "sno", label: "S.No", minWidth: 80, align: "centre" },
    { id: "amount", label: "Amount", minWidth: 110, align: "centre" },
    { id: "date", label: "Date", minWidth: 110, align: "centre" },
    { id: "time", label: "Time", minWidth: 110, align: "centre" },
    { id: "wallet", label: "Wallet", minWidth: 110, align: "centre" },
    { id: "from", label: "from", minWidth: 220, align: "centre" },
  ];

  const handleChangePage = (event, newPage) => {
    if (hasFilteredRows) {
      setPage(newPage);
    } else {
      const lastPage = Math.max(
        0,
        Math.ceil(filteredRows.length / rowsPerPage) - 1
      );
      setPage(lastPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(4),
    },
    "& .MuiInputBase-input": {
      borderRadius: "6px",
      position: "relative",
      backgroundColor: "var(--card-bg)",
      border: "solid 2px rgba(18, 182, 179, 0.4)",
      color: "#fff",
      align: "centre",
      fonttime: "10px",
      padding: "10px 26px 10px 10px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontFamily: "poppins",
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));

  useEffect(() => {
    setHasFilteredRows(filteredRows.length > 0);
  }, [filteredRows]);

  return (
    <div
      className="tab-pane fade  accordion-item"
      id="teamBuildBonus-tab-pane"
      role="tabpanel"
      aria-labelledby="teamBuildBonus-tab"
      tabIndex={0}
    >
      <div id="chart-container"></div>
      <div
        id="collapseOne"
        className="accordion-collapse collapse show  d-lg-block"
        aria-labelledby="headingOne"
        data-bs-parent="#myTabContent"
      >
        <div classs="accordion-body">
          <div className="row g-2 mb-2 justify-content-center">
            <div className="nonworking">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <DemoItem label="Start date" sx={{ color: "#fff" }}>
                    <DatePicker
                      sx={{
                        width: "100%",
                        bgcolor: "var(--card-bg)",
                        border: "solid 2px rgba(18, 182, 179, 0.4)",
                        borderRadius: "6px",
                        color: "white",
                      }}
                      slotProps={{
                        actionBar: { actions: ["clear"] },
                      }}
                      selected={startDate}
                      onChange={handleStartDateChange}
                    />
                  </DemoItem>
                  <DemoItem label="End date">
                    <DatePicker
                      sx={{
                        width: "100%",
                        bgcolor: "var(--card-bg)",
                        border: "solid 2px rgba(18, 182, 179, 0.4)",
                        borderRadius: "6px",
                        color: "white",
                      }}
                      slotProps={{
                        actionBar: { actions: ["clear"] },
                      }}
                      selected={endDate}
                      onChange={handleEndDateChange}
                    />
                  </DemoItem>
                  <DemoItem label="Wallet" sx={{ color: "#fff" }}>
                    <FormControl
                      sx={{ m: 1, minWidth: 120 }}
                      variant="standard"
                    >
                      <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={selectedWallet}
                        onChange={(event) =>
                          setSelectedWallet(event.target.value)
                        }
                        input={<BootstrapInput />}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">All wallet</MenuItem>
                        <MenuItem value="Spot wallet">Spot wallet</MenuItem>
                        <MenuItem value="Naka wallet">Naka wallet</MenuItem>
                      </Select>
                    </FormControl>
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "var(--card-bg)",
                border: "solid 1px rgba(18, 182, 179, 0.4)",
                borderRadius: "5px",
                color: "white",
                display: "flex",
                // height: 650,
                width: "100%",
                padding: "25px",
              }}
            >
              <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  height: "100%",
                  bgcolor: "var(--card-bg)",
                  border: "solid 1px rgba(18, 182, 179, 0.4)",
                  borderRadius: "5px",
                }}
              >
                <TableContainer sx={{ maxHeight: 500 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{ width: "100%" }}
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              color: "White",
                              backgroundColor: "#12b6b3",
                              borderBottom: "solid 0px rgba(18, 182, 179, 0.4)",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRows.length === 0 ? (
                        <TableRow>
                          <TableCell
                            colSpan={columns.length}
                            align="center"
                            style={{ color: "white" }}
                          >
                            No data available
                          </TableCell>
                        </TableRow>
                      ) : (
                        filteredRows
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.sno}
                              >
                                {columns.map((column) => {
                                  const value = row[column.id];

                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{
                                        color: "white",
                                      }}
                                    >
                                      {column.format &&
                                      typeof value === "number"
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableCell
                  style={{
                    color: "white",
                    borderBottom: "solid 0px rgba(18, 182, 179, 0.4)",
                  }}
                >
                  <div className="table-pagination">
                    <TablePagination
                      rowsPerPageOptions={[5, 10]}
                      component="div"
                      count={sponsorHistory.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      sx={{ color: "white" }}
                    />
                  </div>
                </TableCell>
              </Paper>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectModels(["admin", "application"])(TeamBuildBonus);
