import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Web3 from "web3";
import * as CONTRACTS from "../../Contract";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { Button, Modal, Table } from "react-bootstrap";
import { useAccount } from "wagmi";
import Swal from "sweetalert2";
import { injectModels } from "../../redux/injectModels";

function Packages(props) {
  const ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;

  const [showModal, setShow] = useState(false);
  const [price, setPrice] = useState("");
  const [fee, setFee] = useState("");
  const [feeError, setFeeError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [error, setError] = useState("");
  const [data, setData] = useState("");
  const [ownerAddress, setOwnerAddress] = useState("");
  const [web3Instance, setWeb3Instance] = useState("");
  const handleClose = () => {
    setShow(false);
    setPriceError("");
    setFeeError("");
    setError("");
  };

  const handleShow = () => setShow(true);

  const { address } = useAccount({
    onConnect({ address, connector, isReconnected }) {},
    onDisconnect() {
      console.log("Disconnected");
    },
  });

  const handleAdd = async (e) => {
    setPrice("");
    setFee("");
    handleShow();
    setError("");
  };
  const handlePrice = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (isNaN(val) || val.trim() === "") {
      setPriceError("Price is required!");
    } else {
      setPriceError("");
    }
    setPrice(val);
  };
  const handleFee = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (isNaN(val) || val.trim() === "") {
      setFeeError("Fee is required!");
    } else {
      setFeeError("");
    }
    setFee(val);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    if (fee === "" || isNaN(fee) || fee.trim() === "") {
      setFeeError("Fee is required!");
      valid = false;
    } else {
      setFeeError("");
    }

    if (price === "" || isNaN(price) || price.trim() === "") {
      setPriceError("Price is required!");
      valid = false;
    } else {
      setPriceError("");
    }

    if (!valid) {
      return;
    } else {
      setError("");
      props.application.setLoading(true);

      if (address) {
        if (address === ownerAddress) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const NakaContract = new ethers.Contract(
            ADDRESS,
            CONTRACTS.Nakamoto.abi,
            signer
          );
          const priceInWei = ethers.utils.parseUnits(price, "ether");
          const feeInWei = ethers.utils.parseUnits(fee, "ether");

          try {
            const transaction = await NakaContract.addPackage(
              priceInWei,
              feeInWei
            );
            handleClose();
            await transaction.wait();

            await getPackage();
            props.application.setLoading(false);
            toast.success("Package added successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
          } catch (error) {
            props.application.setLoading(false);
            toast.error("User denied transaction signature", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        } else {
          props.application.setLoading(false);
          toast.error(
            "This address does not have any permission to add the package",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
          handleClose();
        }
      } else {
        console.log(error, "3");
        props.application.setLoading(false);
        toast.dismiss();
        toast.error("Please connect the wallettt", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const getPackage = async () => {
    try {
      props.application.setLoading(true);
      if (address) {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

        const web3 = new Web3(provider);

        const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

        // const receiptGetPackages = await contract.methods.getPackages().call();
        const receiptGetPackages = await contract.methods
          .getPackagesWithJoiningDetails(address)
          .call();
        setData(receiptGetPackages.packages_);
        props.application.setLoading(false);
      } else {
        toast.dismiss();
        toast.error("Please connect with any wallet");
        props.application.setLoading(false);
      }
    } catch (err) {
      props.application.setLoading(false);
      console.log(err);
    }
  };
  const getOwner = async () => {
    const provider = window.ethereum
      ? window.ethereum
      : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

    const web3 = new Web3(provider);
    setWeb3Instance(web3);

    const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

    const receipt1 = await contract.methods.owner().call();
    setOwnerAddress(receipt1);
  };

  const handleDeactivate = (packageId) => {
    if (address) {
      Swal.fire({
        title: "Confirmation",
        text: "Are you sure you want to deactivate this Package?",
        showCancelButton: true,
        confirmButtonColor: "#3f6dcf",
        cancelButtonColor: "#373737",
        confirmButtonText: `Yes`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          props.application.setLoading(true);
          if (window.ethereum) {
            if (address === ownerAddress) {
              const provider = new ethers.providers.Web3Provider(
                window.ethereum
              );
              const signer = provider.getSigner();
              const NakaContract = new ethers.Contract(
                ADDRESS,
                CONTRACTS.Nakamoto.abi,
                signer
              );
              try {
                const transaction = await NakaContract.deactivatePackage(
                  packageId
                );
                await transaction.wait();
                handleClose();
                await getPackage();
                props.application.setLoading(false);
                toast.success("Package deactivated successfully!", {
                  position: toast.POSITION.TOP_CENTER,
                });
              } catch (error) {
                if (error.message.includes("ACTION_REJECTED")) {
                  console.error("Transaction rejected:", error.message);
                  props.application.setLoading(false);
                  toast.error("User reject the transaction", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                } else {
                  props.application.setLoading(false);
                  toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  console.error("Error:", error);
                }
              }
            } else {
              props.application.setLoading(false);
              toast.error(
                "This address does not have any permission to deactivate the package",
                {
                  position: toast.POSITION.TOP_CENTER,
                }
              );
              handleClose();
            }
          }
        }
      });
    } else {
      toast.dismiss();
      toast.error("Please connect the wallett", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    getPackage();
    getOwner();
  }, []);

  return (
    <React.Fragment>
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Packages</li>
          </ol>
        </nav>
      </div>
      <div className="verifier-buttton">
        <Button onClick={handleAdd}>Add Package</Button>{" "}
      </div>
      <Modal show={showModal} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton className="modal-main-header">
          <Modal.Title>
            {/* {id === "" ? "Add" : "Update"} Notifications */}
            Add Package
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="searchformfld">
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Price*"
                value={price}
                onChange={handlePrice}
                maxLength={4}
              />
              {priceError && <div className="error">{priceError}</div>}
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Fee*"
                value={fee}
                onChange={handleFee}
                maxLength={4}
              />
              {feeError && <div className="error">{feeError}</div>}
            </div>
          </form>
          <div className="d-flex align-items-center justify-content-center"></div>
        </Modal.Body>
        {error && (
          <div className="col-xs-12 col-md-12 col-lg-12">
            <div className="alert alert-danger">{error}</div>
          </div>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            type="submit"
            variant="primary custom-button-colour"
            onClick={handleSubmit}
          >
            {/* {id === "" ? "Save Changes" : "Update Changes"} */}
            Add Package
          </Button>
        </Modal.Footer>
      </Modal>
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Plans</th>
            <th>Price</th>
            <th>Fee</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            <React.Fragment>
              {data.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>Plan {idx + 1}</td>
                    <td>
                      {web3Instance.utils.fromWei(item.price, "ether")}
                      {" USDT"}
                    </td>
                    <td>
                      {web3Instance.utils.fromWei(item.fee, "ether")}
                      {" USDT"}
                    </td>
                    <td>
                      {item.isActive === true ? (
                        <div
                          className="btn-group gdfty"
                          role="group"
                          aria-label="Third group"
                        >
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => handleDeactivate(item.packageIndex)}
                          >
                            Active
                          </button>
                        </div>
                      ) : (
                        <div
                          className="btn-group gdftytyt"
                          role="group"
                          aria-label="Third group"
                        >
                          <button type="button" className="btn btn-danger">
                            Deactive
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </React.Fragment>
          ) : (
            <tr>
              <td colSpan="10 mt-2">No results found!</td>
            </tr>
          )}
        </tbody>
      </Table>
    </React.Fragment>
  );
}

export default injectModels(["admin", "application"])(Packages);
