import { JWT } from '../../../Components/shared';
import { LOGOUT, LOGIN_SUCCESS, LOGIN_FAILED } from './constants';

const initialState = {
  isLoggedIn: JWT.isValidToken(),
  access_token: JWT.getJwt(),
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, isLoggedIn: true, access_token: action.payload };
    case LOGIN_FAILED:
      return { ...state, isLoggedIn: false, access_token: null };
    case LOGOUT:
      return { ...state, isLoggedIn: false, access_token: null };
    default:
      return state;
  }
};

export default authReducer;