export const routes = {
    DEFAULT: "/",
    DASHBOARD: "/dashboard",
    CONTACT: "/inquiry",
    PROFILE: "/profile",
    EDITPROFILE: "/edit-profile",
    CONTENT_MANAGEMENT: "/content",
    USER_PROFILE: "/user/profile",
    USER_REPORT: "/user/profile/report",
    USER_NETWORK: "/user/profile/network",
    FAQS: "/faqs",
    WHITEPAPER: "/whitepaper",
    USER: "/user",
    TRANSACTION: "/transaction",
    ADMIN_WALLET: "/wallet",
    LOCK_WALLET: "/lockwallet",
    PACKAGES: "/packages",
    NAKAMOTO_TUTORIALS: "/nakamoto-tutorials",
    CHANGEPASSWORD: "/change-password",
    FORGOTPASSWORD: "/auth/forgot-password",
    RESETPASSWORD:"/auth/reset-password",
    NOTIFICATION: "/notification",
    SYNCDB:"/syncdb",
    ERROR: "/*",
};