import store from '../../store';
import * as ACTIONS from './action';

const obj = {

    addContentPage: (data) => store.dispatch(ACTIONS.addContentPage(data)),
    getAllcontentPages: () => store.dispatch(ACTIONS.getAllcontentPages()),
    getContentById: (data) => store.dispatch(ACTIONS.getContentById(data)),
    updateContentPage: (data) => store.dispatch(ACTIONS.updateContentPage(data)),
    deleteContentPages: (data) => store.dispatch(ACTIONS.deleteContentPages(data)),
    getAllFAQs: () => store.dispatch(ACTIONS.getAllFAQs()),
    getFAQsById: (data) => store.dispatch(ACTIONS.getFAQsById(data)),
    addFAQs: (data) => store.dispatch(ACTIONS.addFAQs(data)),
    deleteFAQsById: (data) => store.dispatch(ACTIONS.deleteFAQsById(data)),
    updateFAQs: (data) => store.dispatch(ACTIONS.updateFAQs(data)),
    getAdmin: () => store.dispatch(ACTIONS.getAdmin()),
    updateAdminProfile: (data) => store.dispatch(ACTIONS.updateAdminProfile(data)),
    uploadAdminImage: (data) => store.dispatch(ACTIONS.uploadAdminImage(data)),
    getAllNotification: () => store.dispatch(ACTIONS.getAllNotification()),
    getNotificationById: (data) => store.dispatch(ACTIONS.getNotificationById(data)),
    addNotification: (data) => store.dispatch(ACTIONS.addNotification(data)),
    deleteNotificationById: (id) => store.dispatch(ACTIONS.deleteNotificationById(id)),
    updateNotification: (data) => store.dispatch(ACTIONS.updateNotification(data)),
    changePassword: (data) => store.dispatch(ACTIONS.changePassword(data)),
    resetPassword: (data) => store.dispatch(ACTIONS.resetPassword(data)),
    getAllContact: () => store.dispatch(ACTIONS.getAllContact()),
    createTransaction: (data) => store.dispatch(ACTIONS.createTransaction(data)),
    getTransaction: () => store.dispatch(ACTIONS.getTransaction()),
    getLastTransaction: () => store.dispatch(ACTIONS.getLastTransaction()),
    getUserExists: (address) => store.dispatch(ACTIONS.getUserExists(address)),
    deleteUser: (address) => store.dispatch(ACTIONS.deleteUser(address)),
    deleteUsers: () => store.dispatch(ACTIONS.deleteUsers()),
    getAllUsers: () => store.dispatch(ACTIONS.getAllUsers()),
    addUserDetails: (data) => store.dispatch(ACTIONS.addUserDetails(data)),
    getUserDetails: (address) => store.dispatch(ACTIONS.getUserDetails(address)),
    getUserProfileDetails: (data) =>
    store.dispatch(ACTIONS.getUserProfileDetails(data)),
}
export default obj;