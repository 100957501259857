import React, { useState, useEffect } from "react";
import { useAccount } from "wagmi";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import Web3 from "web3";
import ReactPaginate from "react-paginate";
import * as CONTRACTS from "../../Contract";
import { toast } from "react-toastify";
import { Modal, Button, Table } from "react-bootstrap";
import { injectModels } from "../../redux/injectModels";

function AdminWallet(props) {
  const { address } = useAccount();
  const [percentage, setPerccentage] = useState(0);
  const [availableBalance, setAvailableBalance] = useState("");
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [revenue, setRevenue] = useState(0);
  const [transactions, setTransactions] = useState("");
  const [lastTransactions, setLastTransactions] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const [decimals, setDecimals] = useState("");
  const [showNextButton, setShowNextButton] = useState(true);
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [showTopUpModal1, setShowTopUpModal1] = useState(false);
  const [web3Instance, setWeb3Instance] = useState("");
  const [userCount, setUsersCount] = useState(0);
  const [todaysUsers, setTodaysUsers] = useState([]);
  const [payoutgenrated, setpayoutgenrated] = useState(0);
  const [payoutDistributed, setpayoutDistributed] = useState(0);
  const [owner, setOwner] = useState("");

  const ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;
  const TOKEN_ADRRESS = process.env.REACT_APP_TOKEN_ADDRESS;
  const previousPayoutGenrate = localStorage.getItem("payoutgenerate");
  const previousPayoutDistribute = localStorage.getItem("payoutdistribute");
  const previousPendingPayout = localStorage.getItem("pendingpayout");
  const previousUserCount = localStorage.getItem("todaysuser");
  const currTime = localStorage.getItem("fetchtime");

  const formatDate = (date, turncate) => {
    let day = String(date.getDate()).padStart(2, "0");
    if (turncate) {
      day = Number(day) - 1;
    }
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const getTotalUsersJoinedToday = async () => {
    const provider = window.ethereum
      ? window.ethereum
      : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

    const web3 = new Web3(provider);
    setWeb3Instance(web3);

    const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
    const details = await contract.methods.getAnalytics().call();
    const totalUsers = details.totalUsers_;
    let todaysUsers = [];
    setPerccentage(0);
    setpayoutDistributed(0);
    setpayoutgenrated(0);
    for (let index = totalUsers; index > 0; index--) {
      const details = await contract.methods.getUsers(index - 1, index).call();
      const joiningDate = new Date(details[0].joiningTimestamp * 1000);
      const todayDate = new Date();

      setTodaysUsers(0);

      setpayoutgenrated(
        (pre) =>
          pre +
          (details[0].availableNakaWalletIncome / 1e18 +
            details[0].availableSpotWalletIncome / 1e18)
      );

      setpayoutDistributed(
        (pre) =>
          pre +
          (details[0].totalNakaWalletWithdraw / 1e18 +
            details[0].totalSpotWalletWithdraw / 1e18)
      );
      if (formatDate(joiningDate) === formatDate(todayDate, true)) {
        todaysUsers = [...todaysUsers, details[0]];
      }
      setTodaysUsers(todaysUsers.length);
      setPerccentage((((totalUsers - index) / totalUsers) * 100).toFixed(2));
    }
    setTodaysUsers(todaysUsers.length);

    setPerccentage(100);

    const currentDate = new Date();
    const day = currentDate.toLocaleString("en-us", { weekday: "long" });
    const month = currentDate.toLocaleString("en-us", { month: "long" });
    const time = currentDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

    // setInterval(()=>{
    const currentDateTime = `${day}, ${month} ${currentDate.getDate()}, ${currentDate.getFullYear()} ${time}`;
    localStorage.setItem("fetchtime", currentDateTime);
    localStorage.setItem("todaysuser", todaysUsers.length);
    const data1 = payoutgenrated + payoutDistributed;
    localStorage.setItem("payoutgenerate", data1);
    localStorage.setItem("payoutdistribute", payoutDistributed);
    localStorage.setItem("pendingpayout", payoutgenrated);
    // },3000);
  };

  useEffect(() => {
    if (percentage === 100) {
      // Call the function to update localStorage values
      localStorage.setItem(
        "payoutgenerate",
        payoutgenrated + payoutDistributed
      );
      localStorage.setItem("payoutdistribute", payoutDistributed);
      localStorage.setItem("pendingpayout", payoutgenrated);
      localStorage.setItem("todaysuser", todaysUsers);
      localStorage.setItem("fetchtime", currTime);
    }
  }, [percentage, payoutgenrated, payoutDistributed, todaysUsers, currTime]);

  const getContarctData = async () => {
    const provider = window.ethereum
      ? window.ethereum
      : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

    const web3 = new Web3(provider);
    setWeb3Instance(web3);

    const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

    const details = await contract.methods.getAnalytics().call();
    setUsersCount(details[0]);
    const tokBalance = web3.utils.fromWei(details.tokenBalance_, "ether");
    setAvailableBalance(
      details.tokenBalance_ / Math.pow(10, details.tokenDecimals_)
    );
    // console.log(details.tokenBalance_/Math.pow(10,decimals))
    setDecimals(details.tokenDecimals_);
    const fee = details && details[1][0];
    const finalFee = web3.utils.fromWei(fee, "ether");
    const price = details && details[1][1];
    const finalPrice = web3.utils.fromWei(price, "ether");

    const totalRevenue = Number(finalFee) + Number(finalPrice);
    setRevenue(totalRevenue);
  };

  // const convertTimestampToDateTime = (timestamp) => {
  //   const date = new Date(timestamp * 1000);
  //   return date.toUTCString();
  // };
  const convertTimestampToDateTime = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    let hours = date.getUTCHours();
    const minutes = `0${date.getUTCMinutes()}`.slice(-2);
    const seconds = `0${date.getUTCSeconds()}`.slice(-2);

    let period = "AM";
    if (hours >= 12) {
      period = "PM";
      hours -= 12;
    }
    if (hours === 0) {
      hours = 12;
    }

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${period}`;

    return formattedDateTime;
  };

  const getOwner = async () => {
    try {
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);
      setWeb3Instance(web3);

      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

      const details = await contract.methods.owner().call();

      if (details) {
        setOwner(details);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAdminTransacion = async () => {
    try {
      const responedata = await props.admin.getTransaction();
      if (responedata) {
        const data = responedata.transaction.reverse();
        setFilteredData(data);
        // setTransactions(data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getAdminLastTransacion = async () => {
    try {
      const responedata = await props.admin.getLastTransaction();

      if (responedata) {
        const lastData = responedata.transaction.reverse();
        setLastTransactions(lastData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleWithdraw = async () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9.]/g, "");
    if (numericValue === "") {
      setWithdrawalAmount("");
      setError("Amount is required!");
    } else {
      const sanitizedValue = parseInt(numericValue, 10);

      if (sanitizedValue > 0) {
        setWithdrawalAmount(numericValue);
        setError("");
      } else {
        setError("Please enter a value greater than 0.");
      }
    }
  };

  const handleWithdrawConfirm = async () => {
    if (address) {
      if (owner.toLowerCase() === address.toLowerCase()) {
        props.application.setLoading(true);

        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

        const web3 = new Web3(provider);
        if (withdrawalAmount !== "") {
          if (Number(availableBalance) >= Number(withdrawalAmount)) {
            const tokenAddress_ = TOKEN_ADRRESS;
            const tokenBalance = web3.utils.toWei(withdrawalAmount, "ether");
            const tokenBalance_ = tokenBalance / Math.pow(10, 18 - decimals);

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const NakaContract = new ethers.Contract(
              ADDRESS,
              CONTRACTS.Nakamoto.abi,
              signer
            );
            try {
              const transaction = await NakaContract.withdrawToken(
                tokenAddress_,
                tokenBalance_
              );
              await transaction.wait();

              props.application.setLoading(false);
              toast.dismiss();
              toast.success("Withdraw successfull!");
              const time = Math.floor(Date.now() / 1000);
              const data = {
                tokenAddress: tokenAddress_,
                tokenBalance: tokenBalance_,
                timeStamp: time,
              };
              await props.admin.createTransaction(data);

              setIsModalOpen(false);
              getContarctData();
              setWithdrawalAmount("");
            } catch (error) {
              console.log(error, "this is the error");
              if (error.message.includes("Internal JSON-RPC error")) {
                props.application.setLoading(false);
                toast.dismiss();
                toast.error("Network Error:Something went wrong");
              } else if (error.message.includes("ACTION_REJECTED")) {
                props.application.setLoading(false);
                toast.dismiss();
                toast.error("User reject the transaction", {
                  position: toast.POSITION.TOP_CENTER,
                });
              } else {
                props.application.setLoading(false);
                toast.dismiss();
                toast.error("Error during Withdraw");
              }
            }
          } else {
            props.application.setLoading(false);
            toast.dismiss();
            toast.error("Withdrawal amount exceeds available balance");
          }
        } else {
          props.application.setLoading(false);
          setError("Amount is required!");
        }
      } else {
        props.application.setLoading(false);
        toast.dismiss();
        toast.error("Please connect with owner wallet");
      }
    } else {
      props.application.setLoading(false);
      toast.dismiss();
      toast.error("Please connect with wallet");
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setWithdrawalAmount("");
    setError("");
  };

  const calculateSNo = (index) => {
    return currentPage * rowsPerPage + index + 1;
  };

  const rowsPerPage = 5; // Number of rows to display per page
  const offset = currentPage * rowsPerPage; // Calculate the offset based on current page
  const paginatedData = filteredData.slice(offset, offset + rowsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);

    // Check if it's the first page
    if (selected === 0) {
      setShowPreviousButton(false);
    } else {
      setShowPreviousButton(true);
    }

    // Check if it's the last page
    const pageCount = Math.ceil(filteredData.length / rowsPerPage);
    if (selected === pageCount - 1 || pageCount === 1) {
      // Update the condition here
      setShowNextButton(false);
    } else {
      setShowNextButton(true);
    }
  };

  const renderPagination = () => {
    const pageCount = Math.ceil(filteredData.length / rowsPerPage);
    if (pageCount > 1) {
      return (
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
          forcePage={currentPage}
          containerClassName="pagination"
          activeClassName="active"
          previousLabel={showPreviousButton ? "Previous" : null}
          nextLabel={showNextButton ? "Next" : null}
          breakLabel="..."
          disableInitialCallback={true}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    getContarctData();
    getAdminTransacion();
    getAdminLastTransacion();
    getOwner();
    getTotalUsersJoinedToday();
  }, [availableBalance]);

  return (
    <>
      <Modal show={isModalOpen} onHide={handleModalClose} backdrop="static">
        <Modal.Header closeButton className="modal-main-header">
          <Modal.Title>Enter Withdrawal Amount</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form>
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Amount"
              value={withdrawalAmount}
              onChange={handleChange}
            />
            {error && <div className="error">{error}</div>}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button
            type="submit"
            variant="primary custom-button-colour"
            onClick={handleWithdrawConfirm}
          >
            Withdraw
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Wallet</li>
          </ol>
        </nav>
      </div>
      <section className="section dashboard">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-xxl-3 col-md-8">
                <div className="card info-card sales-card card-pointer brdr-c hgty">
                  <div className="card-body">
                    <h5 className="card-title">
                      Payout generated <span>| Total</span>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa-solid fa-dollar-sign" />
                      </div>
                      <div className="ps-3">
                        <h5>{`${(payoutgenrated + payoutDistributed).toFixed(
                          2
                        )} USDT \n${
                          percentage !== 100 ? `${percentage}%` : ""
                        }`}</h5>
                      </div>
                    </div>
                  </div>
                  {currTime && previousPayoutGenrate !== "0" && (
                    <div className="pervious-dataon">
                      <h6>Last fetched data on :</h6>
                      <span>
                        <i class="fa-regular fa-clock"></i>
                        {currTime}
                      </span>
                      <span className="dollar-sign">
                        {" "}
                        <i class="fa-solid fa-dollar-sign"></i>{" "}
                        {Number(previousPayoutGenrate).toFixed(4)} USDT
                      </span>{" "}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xxl-3 col-md-8 ">
                <div className="card info-card sales-card card-pointer brdr-c hgty">
                  <div className="card-body">
                    <h5 className="card-title">
                      Payout distributed <span>| Total</span>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa-solid fa-dollar-sign" />
                      </div>
                      <div className="ps-3">
                        <h5>{`${payoutDistributed.toFixed(2)} USDT`}</h5>
                      </div>
                    </div>
                  </div>
                  {currTime && previousPayoutDistribute !== "0" && (
                    <div className="pervious-dataon">
                      <h6>Last fetched data on :</h6>
                      <span>
                        <i class="fa-regular fa-clock"></i>
                        {currTime}
                      </span>
                      <span className="dollar-sign">
                        {" "}
                        <i class="fa-solid fa-dollar-sign"></i>{" "}
                        {Number(previousPayoutDistribute).toFixed(4)} USDT
                      </span>{" "}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xxl-3 col-md-8">
                <div className="card info-card sales-card card-pointer brdr-c hgty">
                  <div className="card-body">
                    <h5 className="card-title">
                      Pending payout <span>| Total</span>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa-solid fa-dollar-sign" />
                      </div>
                      <div className="ps-3">
                        <h5>{`${payoutgenrated.toFixed(2)} USDT`}</h5>
                      </div>
                    </div>
                  </div>
                  {currTime && previousPendingPayout !== "0" && (
                    <div className="pervious-dataon">
                      <h6>Last fetched data on :</h6>
                      <span>
                        <i class="fa-regular fa-clock"></i>
                        {currTime}
                      </span>
                      <span className="dollar-sign">
                        {" "}
                        <i class="fa-solid fa-dollar-sign"></i>{" "}
                        {Number(previousPendingPayout).toFixed(4)} USDT
                      </span>{" "}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xxl-3 col-md-8 ">
                <div className="card info-card customers-card card-pointer brdr-c hgty">
                  <div className="card-body">
                    <h5 className="card-title">
                      Register User <span>| Last 24 hours</span>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa-solid fa-user" />
                      </div>
                      <div className="ps-3">
                        <h5>{todaysUsers}</h5>
                      </div>
                    </div>
                  </div>
                  {currTime && (
                    <div className="pervious-dataon">
                      <h6>Last fetched data on :</h6>
                      <span>
                        <i class="fa-regular fa-clock"></i>
                        {currTime}
                      </span>
                      <span className="dollar-sign"> {previousUserCount}</span>{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="user-full-details">
        <div className="trtfy usdt-income">
          <h2>
            Available Balance : {Number(availableBalance).toFixed(4)} USDT
          </h2>
          <p className="avlblance">
            Total Revenue <span>{revenue.toFixed(4)} USDT</span>
          </p>
          <button className="wallet-balance" onClick={handleWithdraw}>
            Withdraw
          </button>
        </div>
      </div>

      <section className="tbing-transaction">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
              role="tab"
              aria-controls="home-tab-pane"
              aria-selected="true"
            >
              Transaction History
            </button>
          </li>
          <li className="nav-item active" role="presentation">
            <button
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile-tab-pane"
              type="button"
              role="tab"
              aria-controls="profile-tab-pane"
              aria-selected="false"
              onClick={getAdminLastTransacion}
            >
              Last 24 hr Transaction
            </button>
          </li>
        </ul>
        <div className="tab-content " id="myTabContent">
          <div
            className="tab-pane fade show active first-trs"
            id="home-tab-pane"
            role="tabpanel"
            aria-labelledby="home-tab"
            tabIndex={0}
          >
            <table>
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Time</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData && paginatedData.length > 0 ? (
                  <React.Fragment>
                    {paginatedData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{calculateSNo(index)}.</td>
                          <td>{Number(item.tokenBalance) / 1000000} USDT</td>
                          <td>{convertTimestampToDateTime(item.timestamps)}</td>
                        </tr>
                      );
                    })}
                  </React.Fragment>
                ) : (
                  <>
                    <div className="tran-history">
                      <span>No Transaction history...</span>
                    </div>
                  </>
                )}
              </tbody>
            </table>
            <div className="table-footer mt-3">
              <strong>Total Count: {filteredData.length}</strong>
              {renderPagination()}
            </div>
          </div>
          <div
            className="tab-pane fade first-trs"
            id="profile-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabIndex={0}
          >
            {/* <div className="tran-history">
              <span>No Transaction history...</span>
            </div> */}
            <table>
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Time</th>
                </tr>
              </thead>
              <tbody>
                {lastTransactions && lastTransactions.length > 0 ? (
                  <>
                    {lastTransactions.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{Number(item.tokenBalance) / 1000000} USDT</td>
                          <td>{convertTimestampToDateTime(item.timestamps)}</td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className="tran-history">
                      <span>No Transaction history...</span>
                    </div>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}
export default injectModels(["application", "admin"])(AdminWallet);
