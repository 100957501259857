import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { injectModels } from "../../redux/injectModels";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import ReactPaginate from "react-paginate";

function Faqs(props) {
  const [showModal, setShow] = useState(false);
  const [error, setError] = useState("");
  const [id, setId] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const handleClose = () => 
  
  {
    setShow(false);
    setError(""); 
  }
  ;
  const handleShow = () => setShow(true);

  const handleAdd = async (e) => {
    setId("");
    setQuestion("");
    setAnswer("");
    handleShow();
    setError("");
  };

  const getData = async () => {
    props.application.setLoading(true);
    try {
      const res = await props.admin.getAllFAQs();
      setFilteredData(res.Faqs.reverse());
      props.application.setLoading(false);
    } catch (error) {
      props.application.setLoading(false);
    }
  };

  const handleQuestion = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setError("Question is required!");
    } else {
      setError("");
    }
    setQuestion(val);
  };

  const handleAnswer = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setError("Answer is required!");
    } else {
      setError("");
    }
    setAnswer(val);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    if (question.trim() === "") {
      setError("Question is required!");
      valid = false;
      return;
    } else {
      setError("");
    }
    if (answer.trim() === "") {
      setError("Answer is required!");
      valid = false;
      return;
    } else {
      setError("");
    }
    if (!valid) {
      setError("Please fill all fields!");
      return;
    } else {
      setError("");
      try {
        props.application.setLoading(true);
        if (id === "") {
          const data = {
            question: question.trim(),
            answer: answer.trim(),
          };
          const responce = await props.admin.addFAQs(data);
          if (responce.status === 200) {
            props.application.setLoading(false);
            toast.success(responce.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.error("Something went wrong!", {
              position: toast.POSITION.TOP_CENTER,
            });
            props.application.setLoading(false);
          }
        } else {
          const data = {
            question: question.trim(),
            answer: answer.trim(),
            id: id,
          };
          const responce = await props.admin.updateFAQs(data);
          if (responce.status === 200) {
            props.application.setLoading(false);
            toast.success(responce.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.error(responce.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            props.application.setLoading(false);
          }
        }
        handleClose();
        setId("");
        getData();
      } catch (err) {
        props.application.setLoading(false);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_CENTER,
        });
        setId("");
        console.log(err.code);
        return Promise.reject(err);
      }
    }
  };

  const handleEdit = async (id) => {
    try {
      props.application.setLoading(true);
      const data = {
        id: id,
      };
      const res = await props.admin.getFAQsById(data);
      setQuestion(res.faqs.question);
      setAnswer(res.faqs.answer);
      setId(id);
      handleShow();
      props.application.setLoading(false);
    } catch (error) {
      props.application.setLoading(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Confirmation",
      text: "Are you sure you want to delete this FAQ?",
      showCancelButton: true,
      confirmButtonColor: "#3f6dcf",
      cancelButtonColor: "#373737",
      confirmButtonText: `Yes`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          props.application.setLoading(true);
          const data = {
            id: id,
          };
          const res = await props.admin.deleteFAQsById(data);
          if (res.status === 200) {
  
            const filteredDataCopy = [...filteredData];
            const index = filteredDataCopy.findIndex((item) => item._id === id);
            if (index !== -1) {
              filteredDataCopy.splice(index, 1);
            }
            setFilteredData(filteredDataCopy);

            const pageCount = Math.ceil(filteredDataCopy.length / rowsPerPage);

            if (currentPage >= pageCount) {
              setCurrentPage(pageCount - 1);
            }

            props.application.setLoading(false);

            toast.success("FAQ is deleted!", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            Swal.fire("FAQ!", "Something went wrong!", "error");
          }
        } catch (err) {
          props.application.setLoading(false);
          console.log(err);
          Swal.fire("FAQ!", "Something went wrong!", "error");
          return Promise.reject(err);
        }
      }
    });
  };
  const rowsPerPage = 10; // Number of rows to display per page
  const offset = currentPage * rowsPerPage; // Calculate the offset based on current page
  const paginatedData = filteredData.slice(offset, offset + rowsPerPage);

  const calculateSNo = (index) => {
    return offset + index + 1;
  };



  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);

    if (selected === 0) {
      setShowPreviousButton(false);
    } else {
      setShowPreviousButton(true);
    }

    const pageCount = Math.ceil(filteredData.length / rowsPerPage);

    if (filteredData.length === 0) {
      setShowNextButton(false);
    } else if (selected === pageCount - 1) {
      setShowNextButton(false);
    } else {
      setShowNextButton(true);
    }
  };

  const renderPagination = () => {
    const pageCount = Math.ceil(filteredData.length / rowsPerPage);
    if (pageCount > 1) {
      return (
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
          forcePage={currentPage}
          containerClassName="pagination"
          activeClassName="active"
          previousLabel={showPreviousButton ? "Previous" : null}
          nextLabel={showNextButton ? "Next" : null}
          breakLabel="..."
          disableInitialCallback={true}
        />
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={routes.DASHBOARD}>Dashboard</Link>
            </li>
            {/* <li className="breadcrumb-item">Content Management</li>*/}
            <li className="breadcrumb-item active">FAQs</li>
          </ol>
        </nav>
      </div>
      {/*isDataLength === false ?  "":<div className="verifier-buttton">
                <Button onClick={handleAdd}>Add FAQs</Button>{" "}s
    </div> */}
      <div className="verifier-buttton">
        <Button onClick={handleAdd}>Add FAQ</Button>{" "}
      </div>
      <Modal show={showModal} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton className="modal-main-header">
          <Modal.Title>{id === "" ? "Add" : "Update"} FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="searchformfld">
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Question*"
                value={question}
                onChange={handleQuestion}
                required
              />
              <textarea
                className="form-control mb-3"
                placeholder="Answer*"
                rows={5}
                cols={5}
                value={answer}
                onChange={handleAnswer}
              />
            </div>
            {error && <span className="error">{error}</span>}
            {/*<div className='d-flex align-items-center justify-content-center'>
                            <Button type="submit" variant="primary custom-button-colour" onClick={handleSubmit}>
                                {id === "" ? 'Save Changes' : 'Update Changes'}
                            </Button>
</div>*/}
          </form>
        </Modal.Body>
        
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            type="submit"
            variant="primary custom-button-colour"
            onClick={handleSubmit}
          >
            {id === "" ? "Save Changes" : "Update Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="table-responsive">
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Question</th>
              <th>Answer</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData && paginatedData.length > 0 ? (
              <React.Fragment>
                {paginatedData.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{calculateSNo(idx)}</td>
                      <td>{item.question}</td>
                      <td>{item.answer}</td>
                      <td>
                        <div className="delete-create">
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              handleEdit(item._id);
                            }}
                          >
                            <i className="fa-solid fa-pen-to-square"></i>
                          </Link>
                          {/*isDataLength === false ?  "":  */}
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              handleDelete(item._id);
                            }}
                          >
                            <i className="fa-sharp fa-solid fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </React.Fragment>
            ) : (
              <tr>
                <td colSpan="10 mt-2">No results found!</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className="table-footer">
        <strong>Total Count: {filteredData.length}</strong>
        {filteredData.length === 0 ? (
          <p>No results found!</p>
        ) : (
          renderPagination()
        )}
      </div>
    </React.Fragment>
  );
}

export default injectModels(["admin", "application"])(Faqs);
