import React from "react";
import { useEffect, useState } from "react";
import { injectModels } from "../../redux/injectModels";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import { Table } from "react-bootstrap";
import _ from "lodash";

function Contact(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState({});
  const [isClearIcon, setIsClearIcon] = useState(true);
  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);

  useEffect(() => {
    getData();
    toggleClearIcon("");
  }, []);

  const getData = async () => {
    props.application.setLoading(true);
    try {
      const res = await props.admin.getAllContact();

      if (res.tickets.length > 0) {
        setData(res.tickets);
        setFilteredData(res.tickets);
        props.application.setLoading(false);
      } else {
        props.application.setLoading(false);
      }
    } catch (error) {
      props.application.setLoading(false);
    }
  };

  var searchList = [
    "firstName",
    "lastName",
    "email",
    "contactNo",
    "message",
    "createdOn",
  ];

  const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery);
    setCurrentPage(0);
    toggleClearIcon(searchQuery);
    const filteredData = data.filter((row) => {
      return Object.values(row).some((value) => {
        const key = Object.keys(row).find((k) => row[k] === value);
        return searchList.includes(key)
          ? value?.toLowerCase?.().includes(searchQuery?.toLowerCase?.())
          : null;
      });
    });
    setFilteredData(filteredData);
  };

  const clearSearch = () => {
    setSearchQuery("");
    toggleClearIcon("");

    const filteredData = data.filter((row) => {
      return Object.values(row).some((value) => {
        return value?.toLowerCase?.().includes("");
      });
    });
    setFilteredData(filteredData);
    setCurrentPage(0);
    setShowPreviousButton(false);
    setShowNextButton(true);
  };

  const toggleClearIcon = (value) => {
    const clearIcon = document.querySelector(".clear-icon");
    if (value === "") {
      clearIcon.style.display = "none";
    } else {
      clearIcon.style.display = "block";
    }
  };

  const rowsPerPage = 10;
  const pageCount = Math.ceil(filteredData.length / rowsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
    if (selected === 0) {
      setShowPreviousButton(false);
    } else {
      setShowPreviousButton(true);
    }
    const pageCount = Math.ceil(filteredData.length / rowsPerPage);
    if (selected === pageCount - 1 || pageCount === 1) {
      setShowNextButton(false);
    } else {
      setShowNextButton(true);
    }
  };

  const renderPagination = () => {
    if (pageCount > 1) {
      return (
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
          forcePage={currentPage}
          containerClassName="pagination"
          activeClassName="active"
          previousLabel={showPreviousButton ? "Previous" : null}
          nextLabel={showNextButton ? "Next" : null}
          breakLabel="..."
          disableInitialCallback={true}
        />
      );
    }
    return null;
  };

  const calculateSNo = (index) => {
    return currentPage * rowsPerPage + index + 1;
  };

  const sortedData = _.orderBy(filteredData, ["createdAt"], ["desc"]);

  const paginatedData = sortedData.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage
  );

  function formatDateTimeFromISO(timestamp) {
    if (!timestamp) return ""; 
    const date = new Date(timestamp);
    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    const hours = `0${date.getUTCHours()}`.slice(-2);
    const minutes = `0${date.getUTCMinutes()}`.slice(-2);
    const seconds = `0${date.getUTCSeconds()}`.slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`;
  }

  return (
    <React.Fragment>
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={routes.DASHBOARD}>Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Inquiry</li>
          </ol>
        </nav>
      </div>
      <div className="search-container">
        <input
          type="text"
          id="searchText"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search..."
          className="form-control mb-3 brdr-c white-text"
        />
        <i className="fas fa-search search-icon"></i>
        {isClearIcon && (
          <i className="fas fa-times clear-icon" onClick={clearSearch}></i>
        )}
      </div>
      <div className="table-responsive">
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email Address</th>
              <th>Message</th>
              <th>Date & Time</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData && paginatedData.length > 0 ? (
              <React.Fragment>
                {paginatedData.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{calculateSNo(idx)}</td>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.email}</td>
                      <td>{item.message}</td>
                      <td>{formatDateTimeFromISO(item.createdAt)}</td>
                    </tr>
                  );
                })}
              </React.Fragment>
            ) : (
              <tr>
                <td colSpan="10 mt-2">No results found!</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className="table-footer">
        <strong>Total Count: {filteredData.length}</strong>
        {renderPagination()}
      </div>
    </React.Fragment>
  );
}

export default injectModels(["admin", "application"])(Contact);
