import React from 'react'
import { Link } from 'react-router-dom'

const NakamotoTurorials = () => {
  return (
    <div className="pagetitle">
    <nav>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Dashboard</Link>
        </li>
        <li className="breadcrumb-item active">Nakamoto Turorial</li>
      </ol>
    </nav>
  </div>
  )
}

export default NakamotoTurorials