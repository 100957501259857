import React from 'react';
import { Link ,useNavigate} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { routes } from '../constants/routes';
import Swal from "sweetalert2";
export default function Header() {

  const navigate = useNavigate();

  const userFirstName =   localStorage.getItem("UserFristName");
  const userLastName =   localStorage.getItem("UserLastName");

  const handleLogOut = () =>{ 
    Swal.fire({
      title: "Confirmation",
      text: "Are you sure you want to Logout?",
      showCancelButton: true,
      confirmButtonColor: "#3f6dcf",
      cancelButtonColor: "#373737",
      confirmButtonText: `Yes`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let email = localStorage.getItem('RememberMeEmail');
          let password = localStorage.getItem('Password');
  
          // Retrieve values to keep
          const keysToKeep = ['todaysuser', 'fetchtime', 'payoutgenerate', 'pendingpayout', 'payoutdistribute'];
          const valuesToKeep = {};
          keysToKeep.forEach(key => {
            const value = localStorage.getItem(key);
            if (value !== null) {
              valuesToKeep[key] = value;
            }
          });
  
          localStorage.clear();
  
          // Restore values
          keysToKeep.forEach(key => {
            if (valuesToKeep[key] !== undefined) {
              localStorage.setItem(key, valuesToKeep[key]);
            }
          });
  
          if (email != null && password != null) {
            localStorage.setItem("RememberMeEmail", email);
            localStorage.setItem("Password", password);
          }
  
          navigate("/");
          toast.success("Logged out successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
        } catch (err) {
          console.log(err);
          Swal.fire("FAQ!", "Something went wrong!", "error");
          return Promise.reject(err);
        }
      }
    });
  }
  


  const handleToggle = () => {
    const list = document.querySelector("body").classList;
    if (list.contains("toggle-sidebar")) {
      list.remove("toggle-sidebar");
    } else {
      list.add("toggle-sidebar");
    }
  };
  

  return (
    <React.Fragment>
      <header id="header" className="header fixed-top d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-between">
          <Link to={routes.DASHBOARD} className="header-logo d-flex align-items-center">
            <img src="/assets/img/nakamoto-logo.png" alt="logo" />
          </Link>
          <i className="fa-solid fa-bars toggle-sidebar-btn" onClick={handleToggle}></i>
        </div>
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown pe-3">
              <div className="nav-link nav-icon nav-profile d-flex align-items-center pe-0 drty"  data-bs-toggle="dropdown">
                <i className="fa-regular fa-user"/>
              </div>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                  <h6>{userFirstName} {userLastName}</h6>
                  <span>Admin</span>
                  <Link className="nav-link" to="#" onClick={handleLogOut}>
                    <i className="fa-solid fa-arrow-right-to-bracket" />
                    <span> Logout</span>
                  </Link>
                </li>
              </ul>
            </li>
            
            <li className="nav-item dropdown pe-3">
            <w3m-button  icon="hide" balance="hide"/>
            </li>
          </ul>
       
        
        </nav>
      </header>
    </React.Fragment>
  )
}
