import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { injectModels } from "../../redux/injectModels";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import parse from "react-html-parser";
function ContentManagement(props) {
  const [showModal, setShow] = useState(false);
  const [error, setError] = useState("");
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [data, setData] = useState({});
  const [view, setView] = useState(false);

  // const parse = require("react-html-parser");

  useEffect(() => {
    getData();
  }, []);

  const handleClose = () => {
    setError("");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const getData = async () => {
    props.application.setLoading(true);
    try {
      const res = await props.admin.getAllcontentPages();
      setData(res.updatedPages);
      props.application.setLoading(false);
    } catch (error) {
      props.application.setLoading(false);
    }
  };

  const handleTitle = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setError("Title is required!");
    } else {
      setError("");
    }
    setTitle(val);
  };


  const handleSubTitle = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setError("Sub Title is required!");
    } else {
      setError("");
    }
    setSubTitle(val);
  };

  const handleDescription = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setError("Description is required!");
    } else {
      setError("");
    }
    setDescription(val);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    if (title.trim() === "") {
      setError("Title is required!");
      valid = false;
      return;
    } else {
      setError("");
    }
    if (slug.trim() === "") {
      setError("Slug is required!");
      valid = false;
      return;
    } else {
      setError("");
    }
    if (subTitle.trim() === "") {
      setError("Sub Title is required!");
      valid = false;
      return;
    } else {
      setError("");
    }
    if (description.trim() === "") {
      setError("Description is required!");
      valid = false;
      return;
    } else {
      setError("");
    }
    if (!valid) {
      setError("Please fill all fields!");
      return;
    } else {
      setError("");
      try {
        props.application.setLoading(true);
        const data = {
          id: id,
          title: title.trim(),
          slug: slug.trim(),
          subTitle: subTitle.trim(),
          description: description,
        };
        const response = await props.admin.updateContentPage(data);
        if (response.status === 200) {
          props.application.setLoading(false);
          toast.success("Updated successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.error("Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
          });
          props.application.setLoading(false);
        }
        handleClose();
        setId("");
        getData();
      } catch (err) {
        props.application.setLoading(false);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_CENTER,
        });
        setId("");
        console.log(err.code);
        return Promise.reject(err);
      }
    }
  };

  const handleEdit = async (id) => {
    const data = {
      id: id,
    };
    const res = await props.admin.getContentById(data);
    setTitle(res.page.title.trimStart());
    setSlug(res.page.slug.trimStart());
    setSubTitle(res.page.subTitle.trimStart());
    setDescription(res.page.description.trimStart());
    setId(id);
    handleShow();
  };
  const handleView = async (id) => {
    const data = {
      id: id,
    };
    const res = await props.admin.getContentById(data);
    setTitle(res.page.title);
    setSlug(res.page.slug);
    setSubTitle(res.page.subTitle);
    setDescription(res.page.description);
    setId(id);
    handleShow();
  };

  return (
    <React.Fragment>
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={routes.DASHBOARD}>Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Content Management</li>
          </ol>
        </nav>
      </div>
      {view ? (
        <Modal
          size="lg"
          backdrop="static"
          show={showModal}
          onHide={handleClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">{slug}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="searchformfld">
                <input
                  type="text"
                  className="form-control mb-3"
                  readOnly
                  value={title}
                />

                <input
                  type="text"
                  className="form-control mb-3"
                  value={subTitle}
                  readOnly
                />
                <textarea
                  className="form-control mb-3"
                  value={description}
                  readOnly
                />
              </div>
            </form>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          size="lg"
          show={showModal}
          onHide={handleClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton className="modal-main-header">
            <Modal.Title id="example-modal-sizes-title-lg">
              {id === "" ? "Add" : "Update"} {slug}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="searchformfld">
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Title"
                  maxLength={40}
                  value={title}
                  onChange={handleTitle}
                  required
                />
               
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Sub Title"
                  maxLength={150}
                  value={subTitle}
                  onChange={handleSubTitle}
                  required
                />
                <textarea
                  className="form-control mb-3"
                  placeholder="Description*"
                  rows={25}
                  cols={25}
                  value={description}
                  onChange={handleDescription}
                />
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  type="submit"
                  variant="primary custom-button-colour"
                  onClick={handleSubmit}
                >
                  {id === "" ? "Save Changes" : "Update Changes"}
                </Button>
              </div>
            </form>
          </Modal.Body>
          {error && (
            <div className="col-xs-12 col-md-12 col-lg-12">
              <div className="alert alert-danger">{error}</div>
            </div>
          )}
        </Modal>
      )}
      <table id="term" className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Sub Title</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            <React.Fragment>
              {data.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>{parse(item.title)}</td>
                    <td style={{"color":"#10a19e"}}>{parse(item.subTitle ? item.subTitle: "<h3>No subtitle found</h3>" )}</td>
                    <td>
                      <div className="delete-create">
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            setView(false);
                            handleEdit(item._id);
                          }}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </Link>
                        {
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              setView(true);
                              handleView(item._id);
                            }}
                          >
                            <i className="fa-solid fa-eye"></i>
                          </Link>
                        }
                      </div>
                    </td>
                  </tr>
                );
              })}
            </React.Fragment>
          ) : (
            <tr>
              <td colSpan="10 mt-2">No results found!</td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
}

export default injectModels(["admin", "application"])(ContentManagement);
