import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Components/loginFiles/Login";
import ForgotPassword from "./Components/loginFiles/ForgotPassword";
import ResetPassword from "./Components/loginFiles/RestPassword";
import Dashboard from "./layout/Das";
import Contact from "./Components/contactUs/Contact";
import Profile from "./Components/adminProfile/Profile";
import EditProfile from "./Components/adminProfile/EditProfile";
import Notification from "./Components/notifications/Notification";
import User from "./Components/userFile/User";
import Loader from "./Components/loader/LoaderFile";
import ChangePassword from "./Components/changePasswordFile/ChangePassword";
import PrivateRoutes from "./utils/PrivateRoutes";
import Error from "./Components/errorFile/Error";
import AdminWallet from "./Components/adminWalletFile/AdminWallet";
import Packages from "./Components/packages/Package";
import NakamotoTutorials from "./Components/nakamotoTutorialsFiles/NakamotoTurorials";
import Whitepaper from "./Components/whitepaperFile/Whitepaper";
import Faqs from "./Components/faqsFiles/Faqs";
import ContentManagement from "./Components/contentManagementFile/ContentManagement";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { arbitrum, bscTestnet, mainnet } from "wagmi/chains";
import { routes } from "./constants/routes";
import UserProfile from "./Components/adminProfile/UserProfile";
import UserReport from "./Components/adminProfile/UserReport";
import SyncDB from "./Components/adminProfile/SyncDB";
import Network from "./Components/adminProfile/Network";

const projectId = "f02b2304b61f62fda06d2ac224312444";

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [arbitrum, mainnet, bscTestnet];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

createWeb3Modal({ wagmiConfig, projectId, chains , featuredWalletIds: [
  'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', //Metamask
  '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', //Trust Wallet
  '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66'  //Token Pocket
],
includeWalletIds: [
  'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', //Metamask
  '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', //Trust Wallet
  '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66'  //Token Pocket
],
excludeWalletIds: [
 'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa' //Coinbase
],
    themeVariables: {
  '--w3m-accent': '#0e9a98',
}});

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Loader />
      <WagmiConfig config={wagmiConfig}>
        <Routes>
          <Route path={routes.DEFAULT} element={<Login />} />
          <Route path={routes.FORGOTPASSWORD} element={<ForgotPassword />} />
          <Route path={routes.RESETPASSWORD + "/:token"} element={<ResetPassword />}/>
          <Route element={<PrivateRoutes />}>
            <Route path={routes.DASHBOARD} element={<Dashboard />} />
            <Route path={routes.CONTACT} element={<Contact />} />
            <Route path={routes.PROFILE} element={<Profile />} />
            <Route path={routes.USER_PROFILE + "/:address"} element={<UserProfile />} />
            <Route path={routes.USER_REPORT + "/:address"} element={<UserReport />} />
            <Route path={routes.USER_NETWORK + "/:address"} element={<Network />} />
            <Route path={routes.EDITPROFILE} element={<EditProfile />} />
            <Route path={routes.CONTENT_MANAGEMENT} element={<ContentManagement />}/>
            <Route path={routes.FAQS} element={<Faqs />} />
            <Route path={routes.WHITEPAPER} element={<Whitepaper />} />
            <Route path={routes.NOTIFICATION} element={<Notification />} />
            <Route path={routes.USER} element={<User />} />
            <Route path={routes.ADMIN_WALLET} element={<AdminWallet />} />
            <Route path={routes.PACKAGES} element={<Packages />} />
            <Route path={routes.SYNCDB} element={<SyncDB />} />
            <Route path={routes.NAKAMOTO_TUTORIALS} element={<NakamotoTutorials />}/>
            <Route path={routes.CHANGEPASSWORD} element={<ChangePassword />} />
          </Route>
          <Route path={routes.ERROR} element={<Error />} />
        </Routes>
      </WagmiConfig>
    </BrowserRouter>
  );
}

export default App;
