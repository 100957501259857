import React, { useEffect, useState } from "react";
import { injectModels } from "../../redux/injectModels";
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";

function User(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState({});
  const [isClearIcon, setIsClearIcon] = useState(true);
  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);

  const imagePath = `${process.env.REACT_APP_API_IMAGEPATH}/`
  const navigate = useNavigate();

  useEffect(() => {
    getData();
    toggleClearIcon("");
  }, []);

  const getData = async () => {
    props.application.setLoading(true);
    try {
      const res = await props.admin.getAllUsers();
      const filteredUsers = res.users.filter(user => user.role != 'admin');
      if (filteredUsers.length > 0) {
        setData(filteredUsers.reverse());
        setFilteredData(filteredUsers)
        props.application.setLoading(false);
      } else {
        props.application.setLoading(false);
      }
    } catch (error) {
      props.application.setLoading(false);
    }
  };
  var serachList = ["firstName","lastName","userId","walletAddress","uplineAddress","user"];

  const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery);
    setCurrentPage(0); // Reset current page to 0 after search
    toggleClearIcon(searchQuery);
    const filteredData = data.filter(row => {
      return Object.values(row).some((value )=> {
        const key = Object.keys(row).find((k) => row[k] === value);


        return (serachList.includes(key))?value?.toLowerCase?.().includes(searchQuery?.toLowerCase?.()):null;
      });
    });
    setFilteredData(filteredData);
  };

  const clearSearch = () => {
    setSearchQuery("");
    let searchText = document.getElementById("searchText");
    searchText.value="";
    toggleClearIcon("");
    const filteredData = data.filter(row => {

     

      return Object.values(row).some(value => {
        return value?.toLowerCase?.().includes("");
      });
    });
    setFilteredData(filteredData);
  };


  const toggleClearIcon = (value) => {
    const clearIcon = document.querySelector(".clear-icon");
    if (value === "") {
      clearIcon.style.display = "none";
    } else {
      clearIcon.style.display = "block";
    }
  };

  const rowsPerPage = 10; // Number of rows to display per page
  const offset = currentPage * rowsPerPage; // Calculate the offset based on current page
  const paginatedData = filteredData.slice(offset, offset + rowsPerPage);


  const redirectProfileHandle = (address) => { 
    navigate(`/user/profile/${address}`);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  
    // Check if it's the first page
    if (selected === 0) {
      setShowPreviousButton(false);
    } else {
      setShowPreviousButton(true);
    }
  
    // Check if it's the last page
    const pageCount = Math.ceil(filteredData.length / rowsPerPage);
    if (selected === pageCount - 1 || pageCount === 1) { // Update the condition here
      setShowNextButton(false);
    } else {
      setShowNextButton(true);
    }
  };

  const handleSync = () =>{

  }

  const renderPagination = () => {
    const pageCount = Math.ceil(filteredData.length / rowsPerPage);
    if (pageCount > 1) {
      return (
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
          forcePage={currentPage}
          containerClassName="pagination"
          activeClassName="active"
          previousLabel={showPreviousButton ? "Previous" : null}
          nextLabel={showNextButton ? "Next" : null}
          breakLabel="..."
          disableInitialCallback={true}
        />
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={routes.DASHBOARD}>Dashboard</Link></li>
            <li className="breadcrumb-item active">Users Management</li>
          </ol>
        </nav>
      </div>
      <div className="search-container">
        <input
          type="text"
          id="searchText"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search..."
          className="form-control mb-3 brdr-c white-text"
        />
        <i className="fas fa-search search-icon"></i>
        {isClearIcon && <i className="fas fa-times clear-icon"  onClick={clearSearch}></i>}
      </div>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th scope="col">S.No.</th>
            {/* <th scope="col">User Count</th> */}
            <th scope="col">Name</th>
            <th scope="col">User Id</th>
         
            <th scope="col">Wallet Address</th>
            <th scope="col">Upline Address</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData && paginatedData.length > 0 ? (
            <React.Fragment>
              {paginatedData.map((item, idx) => {
                return ( 
                  <tr key={idx}>
                    {/* <td>{offset + idx + 1}</td> */}
                    <td>{filteredData.length  - (offset + idx)}</td>
                    <td>{item.firstName} {item.lastName}</td>
                    <td>{item.userId}</td>
                    {/* <td>{item.userName}</td> */}
                    <td>{item.walletAddress}</td>
                    <td>
                    {item.uplineAddress}
                    </td>
                    <td>
                      <div className="delete-create">
                        <Link
                          title="view profile"
                          onClick={(e) => {
                            e.preventDefault();
                            redirectProfileHandle(item.walletAddress);
                          }}
                        >
                          <i className="fa-solid fa-eye"></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </React.Fragment>
          ) : (
            <tr>
              <td colSpan="10 mt-2">No results found!</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="table-footer">
        <strong>Total Count: {filteredData.length}</strong>
        {renderPagination()}
      </div>
    </React.Fragment>
  );
}

export default injectModels(["admin", 'application'])(User);
