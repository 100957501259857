import React, { useState, useEffect, useRef } from "react";
import Web3 from "web3";
import { injectModels } from "../../redux/injectModels";
import * as CONTRACTS from "../../Contract";
import ProgressBar from "react-bootstrap/ProgressBar";
import Lottie from "lottie-react";
import Animation from "../../Animation/syncdb.json";
import { toast } from "react-toastify";

const SyncDB = (props) => {
  const ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;

  const [userCount, setUsersCount] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [corruptedData, setCorruptedData] = useState(0);
  const [verifiedData, setVerifiedData] = useState(0);
  const [fixedData, setFixedData] = useState(0);
  const [deletedData, setDeletedData] = useState(0);
  const [syncPercentage, setSyncPercentage] = useState(0);
  const [actionRef, setActionRef] = useState([]);
  const [errorOccured, setErrorOccured] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckingDuplicatingEntry, setIsCheckingDuplicatingEntry] =
    useState(false);
  const [duplicateEntry, setDuplicateEntry] = useState(null);
  const [message, setMessage] = useState("");
  const containerRef = useRef(null);

  const getContarctData = async () => {
    try {
      props.application.setLoading(true);
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
      const web3 = new Web3(provider);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

      const details = await contract.methods.getAnalytics().call();
      setUsersCount(details[0]);
      props.application.setLoading(false);
    } catch (err) {
      console.log(err, "this is error");
      props.application.setLoading(false);
    }
  };

  const getData = async () => {
    props.application.setLoading(true);
    try {
      const res = await props.admin.getAllUsers();
      const filteredUsers = res.users.filter((user) => user.role != "admin");
      if (filteredUsers.length > 0) {
        setFilteredData(filteredUsers);
        props.application.setLoading(false);
      } else {
        props.application.setLoading(false);
      }
    } catch (error) {
      props.application.setLoading(false);
    }
  };

  const handleSync = async () => {
    try {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      setCorruptedData(0);
      setVerifiedData(0);
      setFixedData(0);
      setSyncPercentage(0);
      setActionRef([]);
      setErrorOccured(0);
      setDeletedData(0);
      setIsCheckingDuplicatingEntry(false);
      setDuplicateEntry(null);

      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
      const web3 = new Web3(provider);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
      const receiptUser = await contract.methods.getAnalytics().call();

      let totalUsers = receiptUser.totalUsers_;
      for (let index = 0; index < totalUsers; index++) {
        // Getting the user
        setMessage(
          <p
            style={{ color: "white" }}
          >{`Getting New User From Blockchain : ${index}`}</p>
        );
        setActionRef((pre) => [
          ...pre,
          <p
            style={{ color: "white" }}
          >{`Getting New User From Blockchain : ${index}`}</p>,
        ]);
        const newReceiptUsers = await contract.methods
          .getUsers(index, index + 1)
          .call();

        // Consoling the user
        setMessage(
          <p
            style={{ color: "white" }}
          >{`User Data Fetched Successfully from Blockchain: ${newReceiptUsers[0].userAddress}`}</p>
        );
        setActionRef((pre) => [
          ...pre,
          <p
            style={{ color: "white" }}
          >{`User Data Fetched Successfully from Blockchain: ${newReceiptUsers[0].userAddress}`}</p>,
        ]);
        try {
          // Getting User from API Database
          setMessage(
            <p
              style={{ color: "white" }}
            >{`Getting User From Database : ${newReceiptUsers[0].userAddress}`}</p>
          );
          setActionRef((pre) => [
            ...pre,
            <p
              style={{ color: "white" }}
            >{`Getting User From Database : ${newReceiptUsers[0].userAddress}`}</p>,
          ]);

          const response = await props.admin.getUserDetails(
            newReceiptUsers[0].userAddress
          );

          setMessage(
            <p
              style={{ color: "white" }}
            >{`User Data Fetched Successfully from Blockchain:${newReceiptUsers[0].userAddress}`}</p>
          );
          setActionRef((pre) => [
            ...pre,
            <p
              style={{ color: "white" }}
            >{`User Data Fetched Successfully from Blockchain:${newReceiptUsers[0].userAddress}`}</p>,
          ]);

          // Consoling the database data

          // Showing Current Activity
          setMessage(
            <p
              style={{ color: "white" }}
            >{`Checking user Authenticty Matching Their Data: ${newReceiptUsers[0].userAddress}`}</p>
          );
          setActionRef((pre) => [
            ...pre,
            <p
              style={{ color: "white" }}
            >{`Checking user Authenticty Matching Their Data: ${newReceiptUsers[0].userAddress}`}</p>,
          ]);

          //Now check if user have same upline id as in blockchain if not then update user upline.
          if (
            response.data.data.user.uplineAddress.toString().toLowerCase() ===
            newReceiptUsers[0].uplineID.toString().toLowerCase()
          ) {
            setMessage(
              <p
                style={{ color: "green" }}
              >{`User: ${newReceiptUsers[0].userAddress} Verified, Perfect Data Found`}</p>
            );
            setActionRef((pre) => [
              ...pre,
              <p
                style={{ color: "green" }}
              >{`User: ${newReceiptUsers[0].userAddress} Verified, Perfect Data Found`}</p>,
            ]);

            setVerifiedData((pre) => pre + 1);
            await new Promise((resolve) => setTimeout(resolve, 100));
          } else {
            setCorruptedData((pre) => pre + 1);
            setMessage(
              <p
                style={{ color: "red" }}
              >{`User: ${newReceiptUsers[0].userAddress} Has Corrpted Data, Now AI is this Data Fixing It.`}</p>
            );
            setActionRef((pre) => [
              ...pre,
              <p
                style={{ color: "red" }}
              >{`User: ${newReceiptUsers[0].userAddress} Has Corrpted Data, Now AI is this Data Fixing It.`}</p>,
            ]);

            try {
              setMessage(
                <p
                  style={{ color: "red" }}
                >{`User: ${newReceiptUsers[0].userAddress} is getting deleted from database.`}</p>
              );
              setActionRef((pre) => [
                ...pre,
                <p
                  style={{ color: "red" }}
                >{`User: ${newReceiptUsers[0].userAddress} is getting deleted from database.`}</p>,
              ]);

              const deleteUser = await props.admin.deleteUser(
                newReceiptUsers[0].userAddress
              );
              setMessage(
                <p
                  style={{ color: "red" }}
                >{`User: ${newReceiptUsers[0].userAddress} delete Response: ${deleteUser}.`}</p>
              );
              setActionRef((pre) => [
                ...pre,
                <p
                  style={{ color: "red" }}
                >{`User: ${newReceiptUsers[0].userAddress} delete Response: ${deleteUser}.`}</p>,
              ]);
              setMessage(
                <p
                  style={{ color: "red" }}
                >{`User: ${newReceiptUsers[0].userAddress} is getting deleted Successfully.`}</p>
              );
              setActionRef((pre) => [
                ...pre,
                <p
                  style={{ color: "red" }}
                >{`User: ${newReceiptUsers[0].userAddress} is getting deleted Successfully.`}</p>,
              ]);

              const data = {
                fname: response.data.data.user.firstName,
                lname: response.data.data.user.lastName,
                avatar: response.data.data.user.avatar,
                walletAddress: newReceiptUsers[0].userAddress,
                uplineAddress: newReceiptUsers[0].uplineID,
                joiningDate: newReceiptUsers[0].joiningTimestamp,
                isJoined: true,
                userId: newReceiptUsers[0].userAddress.slice(-7),
                uplineId: newReceiptUsers[0].uplineID.slice(-7),
              };
              setMessage(
                <p
                  style={{ color: "red" }}
                >{`User: ${newReceiptUsers[0].userAddress} is getting Motified by fresh data.`}</p>
              );
              setActionRef((pre) => [
                ...pre,
                <p
                  style={{ color: "red" }}
                >{`User: ${newReceiptUsers[0].userAddress} is getting Motified by fresh data.`}</p>,
              ]);

              const add = await props.admin.addUserDetails(data);
              setFixedData((pre) => pre + 1);
              setMessage(
                <p
                  style={{ color: "green" }}
                >{`User: ${newReceiptUsers[0].userAddress} is adding Response ${add}`}</p>
              );
              setActionRef((pre) => [
                ...pre,
                <p
                  style={{ color: "green" }}
                >{`User: ${newReceiptUsers[0].userAddress} is adding Response ${add}`}</p>,
              ]);

              setMessage(
                <p
                  style={{ color: "green" }}
                >{`User: ${newReceiptUsers[0].userAddress} is getting Motified Successfully.`}</p>
              );
              setActionRef((pre) => [
                ...pre,
                <p
                  style={{ color: "green" }}
                >{`User: ${newReceiptUsers[0].userAddress} is getting Motified Successfully.`}</p>,
              ]);
            } catch (err) {
              setErrorOccured((pre) => pre + 1);
              setMessage(
                <p
                  style={{ color: "red" }}
                >{`User: ${newReceiptUsers[0].userAddress} Error Occured: ${err}`}</p>
              );
              setActionRef((pre) => [
                ...pre,
                <p
                  style={{ color: "red" }}
                >{`User: ${newReceiptUsers[0].userAddress} Error Occured: ${err}`}</p>,
              ]);
            }
          }
        } catch (exception) {
          try {
            console.log(exception);
            //User Does Exists, Add this user calling Create user Function
            setCorruptedData((pre) => pre + 1);

            setMessage(
              <p
                style={{ color: "red" }}
              >{`User: ${newReceiptUsers[0].userAddress} is Not Found In Database. Adding a fresh Data.`}</p>
            );
            setActionRef((pre) => [
              ...pre,
              <p
                style={{ color: "red" }}
              >{`User: ${newReceiptUsers[0].userAddress} is Not Found In Database. Adding a fresh Data.`}</p>,
            ]);

            //Deleting in condition may exist
            const deleteUser = await props.admin.deleteUser(
              newReceiptUsers[0].userAddress
            );
            setMessage(
              <p
                style={{ color: "red" }}
              >{`User: ${newReceiptUsers[0].userAddress} delete Response: ${deleteUser}.`}</p>
            );
            setActionRef((pre) => [
              ...pre,
              <p
                style={{ color: "red" }}
              >{`User: ${newReceiptUsers[0].userAddress} delete Response: ${deleteUser}.`}</p>,
            ]);
            setMessage(
              <p
                style={{ color: "red" }}
              >{`User: ${newReceiptUsers[0].userAddress} is getting deleted Successfully.`}</p>
            );
            setActionRef((pre) => [
              ...pre,
              <p
                style={{ color: "red" }}
              >{`User: ${newReceiptUsers[0].userAddress} is getting deleted Successfully.`}</p>,
            ]);

            const data = {
              fname: "Hello",
              lname: "User",
              avatar:
                "https://api.nakatoken.io/images/8efd7c6199a0e0cba4428e774778f377.1707123104916.png",
              walletAddress: newReceiptUsers[0].userAddress,
              uplineAddress: newReceiptUsers[0].uplineID,
              joiningDate: newReceiptUsers[0].joiningTimestamp,
              isJoined: true,
              userId: newReceiptUsers[0].userAddress.slice(-7),
              uplineId: newReceiptUsers[0].uplineID.slice(-7),
            };

            setMessage(
              <p
                style={{ color: "red" }}
              >{`User: ${newReceiptUsers[0].userAddress} is getting Motified by fresh data.`}</p>
            );
            setActionRef((pre) => [
              ...pre,
              <p
                style={{ color: "red" }}
              >{`User: ${newReceiptUsers[0].userAddress} is getting Motified by fresh data.`}</p>,
            ]);

            const add = await props.admin.addUserDetails(data);
            setMessage(
              <p
                style={{ color: "green" }}
              >{`User: ${newReceiptUsers[0].userAddress} is adding Response ${add}`}</p>
            );
            setActionRef((pre) => [
              ...pre,
              <p
                style={{ color: "green" }}
              >{`User: ${newReceiptUsers[0].userAddress} is adding Response ${add}`}</p>,
            ]);
            setMessage(
              <p
                style={{ color: "green" }}
              >{`User: ${newReceiptUsers[0].userAddress} is getting Motified Successfully.`}</p>
            );
            setActionRef((pre) => [
              ...pre,
              <p
                style={{ color: "green" }}
              >{`User: ${newReceiptUsers[0].userAddress} is getting Motified Successfully.`}</p>,
            ]);

            setFixedData((pre) => pre + 1);
            await new Promise((resolve) => setTimeout(resolve, 100));
          } catch (exception) {
            console.log(exception);
            setMessage(
              <p
                style={{ color: "red" }}
              >{`User: ${newReceiptUsers[0].userAddress} Error Occured while Handling Data Not Found ${exception}`}</p>
            );
            setActionRef((pre) => [
              ...pre,
              <p
                style={{ color: "red" }}
              >{`User: ${newReceiptUsers[0].userAddress} Error Occured while Handling Data Not Found ${exception}`}</p>,
            ]);

            setErrorOccured((pre) => pre + 1);
          }
        }
        let percentage = ((index + 1) / totalUsers) * 100;
        percentage = Math.floor(percentage);
        setSyncPercentage(percentage / 2);
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }

      //Iterate through Database Users users
      // {
      // Check for the Entry in Blockchain
      // if(entry is not correct in Blockchain){
      // Delete it
      // }
      // }

      for (let index = 0; index < filteredData.length; index++) {
        const e = filteredData[index];
        try {
          setMessage(
            <p
              style={{ color: "wheat" }}
            >{`User: ${e.walletAddress} checking if avalable on blockchain`}</p>
          );
          setActionRef((pre) => [
            ...pre,
            <p
              style={{ color: "wheat" }}
            >{`User: ${e.walletAddress} checking if avalable on blockchain`}</p>,
          ]);
          const newReceiptUsers = await contract.methods
            .usersProfile(e.walletAddress)
            .call();
          if (!newReceiptUsers.isJoined) {
            setMessage(
              <p
                style={{ color: "red" }}
              >{`User: ${e.walletAddress} not avalable on blockchain, Now deleting`}</p>
            );
            setActionRef((pre) => [
              ...pre,
              <p
                style={{ color: "red" }}
              >{`User: ${e.walletAddress} not avalable on blockchain, Now deleting`}</p>,
            ]);

            // Delete User
            const deleteUser = await props.admin.deleteUser(e.walletAddress);
            setDeletedData((pre) => pre + 1);
            setMessage(
              <p
                style={{ color: "green" }}
              >{`User: ${e.walletAddress} removed from database`}</p>
            );
            setActionRef((pre) => [
              ...pre,
              <p
                style={{ color: "green" }}
              >{`User: ${e.walletAddress} removed from database`}</p>,
            ]);
          } else {
            setMessage(
              <p
                style={{ color: "green" }}
              >{`User: ${e.walletAddress} is avalable on blockchain`}</p>
            );
            setActionRef((pre) => [
              ...pre,
              <p
                style={{ color: "green" }}
              >{`User: ${e.walletAddress} is avalable on blockchain`}</p>,
            ]);
          }
        } catch (exception) {
          setErrorOccured((pre) => pre + 1);
          console.log(exception);
          setMessage(
            <p
              style={{ color: "red" }}
            >{`User: ${e.walletAddress} Error  ${exception}`}</p>
          );
          setActionRef((pre) => [
            ...pre,
            <p
              style={{ color: "red" }}
            >{`User: ${e.walletAddress} Error  ${exception}`}</p>,
          ]);
        }
        let percentage = ((index + 1) / filteredData.length) * 100;
        percentage = Math.floor(percentage);
        percentage = percentage / 2;
        percentage = percentage + 50;
        setSyncPercentage(percentage);
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
      setSyncPercentage(0);
      setIsCheckingDuplicatingEntry(true);
      const duplicateEntries = {};
      for (let index = 0; index < filteredData.length; index++) {
        const e = filteredData[index].walletAddress.toString().toLowerCase();
        // Check if this wallet address has been encountered before
        if (duplicateEntries[e]) {
          duplicateEntries[e].count++; // Increment count if it's a duplicate
        } else {
          duplicateEntries[e] = { count: 1, entries: [] }; // Initialize count and entries array
        }
        duplicateEntries[e].entries.push(filteredData[index].walletAddress); // Push the entry to the entries array
      }

      // Filter out entries with count more than one
      const duplicates = Object.keys(duplicateEntries)
        .filter((key) => duplicateEntries[key].count > 1)
        .map((key) => ({
          walletAddress: key,
          count: duplicateEntries[key].count,
          entries: duplicateEntries[key].entries,
        }));

      for (let p = 0; p < duplicates.length; p++) {
        const e = duplicates[p];
        for (let i = 0; i < e.count - 1; i++) {
          const deleteUser = await props.admin.deleteUser(e.entries[i]);
          setDeletedData((pre) => pre + 1);
        }
        setSyncPercentage((p / duplicates.length) * 100);
      }
      setDuplicateEntry(duplicates);
      setIsCheckingDuplicatingEntry(false);
      setSyncPercentage(0);
      setIsLoading(false);
      toast.success("Data Syncronization successfully completed!", {
        position: toast.POSITION.TOP_CENTER,
      });
      await getData();
    } catch (exception) {
      console.log(exception);
      setSyncPercentage(0);
      setIsLoading(false);
      toast.error(` Data Syncronization failed!  ${exception}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    getContarctData();
    getData();
  }, []);

  return (
    <>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="sync-btn">
          <p>
            BlockChain Users : <span>{userCount}</span>
          </p>
        </div>
        <div className="sync-btn">
          <p>
            Database Users : <span>{filteredData.length}</span>
          </p>
        </div>
      </div>
      {!isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <button
            style={{ alignSelf: "center" }}
            className="btn btn-primary custom-button-colour"
            onClick={handleSync}
          >
            Sync Now
          </button>
        </div>
      )}
      {isLoading && (
        <div>
          <Lottie
            style={{ height: 250, marginTop: 50 }}
            animationData={Animation}
            loop={true}
          />
          <div className="gfjejerg">
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                gap: "20px",
                justifyContent: "space-between",
              }}
            >
              <p>
                Verified Data :
                <span style={{ color: "white", fontWeight: "bold" }}>
                  {" "}
                  {verifiedData}
                </span>
              </p>
              <p>
                Courapted Data :
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {" "}
                  {corruptedData}
                </span>{" "}
              </p>
              <p>
                Fixed Data :
                <span style={{ color: "white", fontWeight: "bold" }}>
                  {" "}
                  {fixedData}
                </span>
              </p>
              <p>
                Deleted Data :
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {" "}
                  {deletedData}
                </span>
              </p>
              <p>
                Error Occured :
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {" "}
                  {errorOccured}
                </span>
              </p>
            </div>
            {isCheckingDuplicatingEntry && (
              <p>Now Checking duplicate Entries</p>
            )}

            <ProgressBar now={syncPercentage} label={`${syncPercentage}%`} />
          </div>

          <p style={{ width: "100%", textAlign: "center" }}>
            Current Action : <span>{message}</span>{" "}
          </p>
        </div>
      )}
      {duplicateEntry !== null && (
        <div
          className="new-action-progress"
          ref={containerRef}
          style={{
            overflowY: "scroll",
            height: isLoading ? "300px" : "100%",
            backgroundColor: "black",
            padding: "25px",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          {
            <div>
              {duplicateEntry.map((e) => (
                <p style={{ width: "100%" }}>
                  {e.walletAddress} Has Duplicate Count :{" "}
                  <span style={{ color: "wheat" }}>{e.count}</span>{" "}
                </p>
              ))}
            </div>
          }
        </div>
      )}
      <div
        className="new-action-progress"
        ref={containerRef}
        style={{
          overflowY: "scroll",
          height: isLoading ? "300px" : "100%",
          backgroundColor: "black",
          padding: "25px",
          margin: "10px",
          borderRadius: "10px",
        }}
      >
        {actionRef.length > 0 && actionRef.map((e) => e)}
      </div>
    </>
  );
};

export default injectModels(["admin", "application"])(SyncDB);
