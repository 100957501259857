export const ABOUT_GET_ALL = 'ABOUT_GET_ALL'
export const ABOUT_POST = "ABOUT_POST"
export const ABOUT_FAILLED = "ABOUT_FAILLED"
export const ABOUT_UPDATE = "ABOUT_UPDATE"

export const TERM_GET_ALL = 'TERM_GET_ALL'
export const TERM_POST = "TERM_POST"
export const TERM_FAILLED = "TERM_FAILLED"
export const TERM_UPDATE = "TERM_UPDATE"

export const LEGAL_GET_ALL = 'LEGAL_GET_ALL'
export const LEGAL_POST = "LEGAL_POST"
export const LEGAL_FAILLED = "LEGAL_FAILLED"
export const LEGAL_UPDATE = "LEGAL_UPDATE"

export const NOTIFICATION_GET_ALL = 'NOTIFICATION_GET_ALL'
export const NOTIFICATION_POST = "NOTIFICATION_POST"
export const NOTIFICATION_FAILLED = "NOTIFICATION_FAILLED"
export const NOTIFICATION_UPDATE = "NOTIFICATION_UPDATE"

export const PARTNER_GET_ALL = 'PARTNER_GET_ALL'
export const PARTNER_POST = "PARTNER_POST"
export const PARTNER_FAILLED = "PARTNER_FAILLED"
export const PARTNER_UPDATE = "PARTNER_UPDATE"

export const PRIVACY_GET_ALL = 'PRIVACY_GET_ALL'
export const PRIVACY_POST = "PRIVACY_POST"
export const PRIVACY_FAILLED = "PRIVACY_FAILLED"
export const PRIVACY_UPDATE = "PRIVACY_UPDATE"

export const HELP_GET_ALL = 'HELP_GET_ALL'
export const HELP_POST = "HELP_POST"
export const HELP_FAILLED = "HELP_FAILLED"
export const HELP_UPDATE = "HELP_UPDATE"

export const CONTACT_GET_ALL = "CONTACT_GET_ALL"
export const CONTACT_FAILLED = "CONTACT_FAILLED"

export const USER_GET_ALL = 'USER_GET_ALL'
export const USER_POST = "USER_POST"
export const USER_FAILLED = "USER_FAILLED"
export const USER_UPDATE = "USER_UPDATE"

export const NFT_GET_ALL = 'NFT_GET_ALL'
export const NFT_POST = "NFT_POST"
export const NFT_FAILLED = "NFT_FAILLED"
export const NFT_UPDATE = "NFT_UPDATE"

export const PASSWORD_FAILED = "PASSWORD_FAILED"
export const PASSWORD_UPDATE = "PASSWORD_UPDATE"

export const USER_PROFILEUPDATE = "USER_PROFILEUPDATE"

export const REPORT_POST = "REPORT_POST"
export const REPORT_FAILLED = "REPORT_FAILLED"

export const CURRENCY_GET_ALL = 'CURRENCY_GET_ALL'
export const CURRENCY_POST = "CURRENCY_POST"
export const CURRENCY_FAILLED = "CURRENCY_FAILLED"
export const CURRENCY_UPDATE = "CURRENCY_UPDATE"