import axios from "axios";
import Web3 from "web3";
import * as CONSTANTS from "./constants";
import * as CONTRACT from "../../../Contract";

export const addContentPage = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;

    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/page/create-page`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateContentPage = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/page/update-content`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllcontentPages = () => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/page/get-all-pages`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getContentById = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/page/get-conent?id=${data.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteContentPages = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/page/remove-page`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllFAQs = () => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/faqs/get-faqs`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getFAQsById = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/faqs/get-one?id=${data.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const addFAQs = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/faqs/create-faqs`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateFAQs = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/faqs/update-faqs`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteFAQsById = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/faqs/remove-faqs`,
      data,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAdmin = () => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/user/get-one`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateAdminProfile = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/user/update`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      dispatch({ type: CONSTANTS.USER_PROFILEUPDATE, payload: response.data });
      return response;
    } else {
      dispatch({ type: CONSTANTS.USER_FAILLED, payload: null });
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const uploadAdminImage = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/upload/upload`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const changePassword = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/auth/change-password`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      dispatch({ type: CONSTANTS.PASSWORD_UPDATE, payload: response.data });
      return response;
    } else {
      dispatch({ type: CONSTANTS.PASSWORD_FAILED, payload: null });
      return Promise.reject(new Error(response.data.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const resetPassword = (data) => async (dispatch, getState) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/auth/reset-password`,
      data
    );

    if (response.status === 200 && response.data) {
      dispatch({ type: CONSTANTS.PASSWORD_UPDATE, payload: response.data });
      return response.data;
    } else {
      dispatch({ type: CONSTANTS.PASSWORD_FAILED, payload: null });
      return { success: false };
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: CONSTANTS.PASSWORD_FAILED, payload: null });
    return Promise.reject(error);
  }
};

export const resetPassword1 = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/auth/reset-password/:${data.token}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      dispatch({ type: CONSTANTS.PASSWORD_UPDATE, payload: response.data });
      return response;
    } else {
      dispatch({ type: CONSTANTS.PASSWORD_FAILED, payload: null });
      return Promise.reject(new Error(response.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllNotification = () => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/notification/get-all-notification`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 201 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getNotificationById = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/notification/get-one?id=${data.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const addNotification = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/notification/push-notification-to-user`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      dispatch({ type: CONSTANTS.NOTIFICATION_POST, payload: data });
      return response;
    } else {
      dispatch({ type: CONSTANTS.NOTIFICATION_FAILLED, payload: null });
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateNotification = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;

    if (!data.id) {
      throw new Error("Notification id is undefined or null");
    }
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/notification/update-one`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response;
    } else {
      dispatch({ type: CONSTANTS.NOTIFICATION_FAILLED, payload: null });
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteNotificationById = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/notification/remove-one`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
export const getAllContact = () => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;

    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/contact/get-all-emails`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
//End

export const getUserDetails = (address) => async (dispatch, getState) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/user/get-user-detail?address=${address}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const createTransaction = (data) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/transaction/create-transaction`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.data.success === true) {
      return response.data;
    } else {
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};
export const getTransaction = () => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/transaction/get-transaction`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.data.success === true) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
export const getLastTransaction = () => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/transaction/last-transaction`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.data.success === true) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllUsers = () => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/user/get-all-users`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const addUserDetails = (data) => async (dispatch, getState) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/user/create-user`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getUserExists = (address) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/user/check-user-exist?walletAddress=${address}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (response && response.status === 200 && response.data) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteUser = (address) => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/user/delete-users?walletAddress=${address}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteUsers = () => async (dispatch, getState) => {
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/user/remove-users`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
export const getUserProfileDetails =
  (address) => async (dispatch, getState) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/user/get-user-detail?address=${address}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.status === 200 && response.data) {
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  };
