import React from 'react'
import { Routes } from '../constants';
import { Link, useLocation } from "react-router-dom";

export default function SideBar() {

  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");

  return (
    <React.Fragment>
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <Link className={`nav-link ${pathname === "/dashboard" ? "collapsed active" : ""} `} to={Routes.DASHBOARD}>
            <i className={`fa-solid fa-dashboard ${pathname === "/dashboard" ? "collapsed active" : ""} `}/>
            <span>Dashboard</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${splitLocation[1] === "user" ? "collapsed active" : ""} `} to={Routes.USER}>
            <i className={`fa-solid fa-users ${splitLocation[1] === "user" ? "collapsed active" : ""} `}></i>
            <span>Users</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${splitLocation[1] === "profile" || splitLocation[1] === "edit-profile"  ? "collapsed active" : ""} `} to={Routes.PROFILE}>
          <i className={`fa-solid fa-user  ${splitLocation[1] === "profile"  || splitLocation[1] === "edit-profile" ? "collapsed active" : ""} `}></i>
            <span>Profile</span>
          </Link>
        </li>
      <li className="nav-item">
      <Link className={`nav-link ${splitLocation[1] === "wallet" ? "collapsed active" : ""} `} to={Routes.ADMIN_WALLET}>
        <i className={`fa-solid fa-wallet ${splitLocation[1] === "wallet" ? "collapsed active" : ""} `}></i>
        <span>Wallet</span>
      </Link>
    </li>
    <li className="nav-item">
          <Link className={`nav-link ${splitLocation[1] === "notification" ? "collapsed active" : ""} `} to={Routes.NOTIFICATION}>
            <i className={`fa-sharp fa-solid fa-bell ${splitLocation[1] === "notification" ? "collapsed active" : ""} `}></i>
            <span>Notifications</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${splitLocation[1] === "packages" ? "collapsed active" : ""} `} to={Routes.PACKAGES}>
            <i className={`fa-solid fa-ticket ${splitLocation[1] === "packages" ? "collapsed active" : ""} `}></i>
            <span>Package</span>
          </Link>
        </li>
        
        <li className="nav-item">
          <Link className={`nav-link ${splitLocation[1] === "inquiry" ? "collapsed active" : ""} `} to={Routes.CONTACT}>
            <i className={`fa-solid fa-address-book  ${splitLocation[1] === "inquiry" ? "collapsed active" : ""} `}></i>
            <span>Inquiry</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${splitLocation[1] === "syncdb" ? "collapsed active" : ""} `} to={Routes.SYNCDB}>
            <i className={`fa-solid fa-address-book  ${splitLocation[1] === "syncdb" ? "collapsed active" : ""} `}></i>
            <span>DB Synchronization</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${splitLocation[1] === "change-password" ? "collapsed active" : ""} `} to={Routes.CHANGEPASSWORD}>
          <i className={`fa-solid fa-unlock ${splitLocation[1] === "change-password" ? "collapsed active" : ""} `}></i>
            <span>Change Password</span>
          </Link>
        </li>
         <li className="nav-item">
                <Link className={`nav-link ${splitLocation[1] === "faqs" ? "collapsed active" : ""} `} to={Routes.FAQS}>
                  <i className={`fa-solid fa-file-circle-question ${splitLocation[1] === "faqs" ? "collapsed active" : ""} `} ></i>
                  <span>FAQs</span>
                </Link>
              </li>
        <li className="nav-item">
        <Link className={`nav-link ${splitLocation[1] === "content" ? "collapsed active" : ""} `} to={Routes.CONTENT_MANAGEMENT}>
          <i className={`fa-solid fa-money-check-dollar ${splitLocation[1] === "content" ? "collapsed active" : ""} `} ></i>
          <span>Content Management</span>
        </Link>
      </li>
      </ul>
    </aside>
  </React.Fragment>
  )
}
