import React from "react";



function Whitepaper(props){

    
    return(
        <React.Fragment> 
        <h1>Whitepaper</h1>
        
        </React.Fragment>
    )
}

export default Whitepaper;