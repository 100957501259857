import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { injectModels } from "../../redux/injectModels";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";

function Notification(props) {
  const [showModal, setShow] = useState(false);
  const [error, setError] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [detail, setDetail] = useState("");
  const [detailError, setDetailError] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const handleClose = () => 
  {
  setShow(false)
  // setError("");
  setDetailError("")
  setNameError("");
  }
  
  const handleShow = () => setShow(true);

  const handleAdd = async (e) => {
    setId("");
    setName("");
    setDetail("");
    handleShow();
    setError("");
  };

  const getData = async () => {
    props.application.setLoading(true);
    try {
      const res = await props.admin.getAllNotification();
      setFilteredData(res.obj.notifications);
      props.application.setLoading(false);
    } catch (error) {
      props.application.setLoading(false);
    }
  };

  const handleName = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setNameError("Notification Name is required!");
    } else {
      setNameError("");
    }
    setName(val);
  };

  const handleDetail = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setDetailError("Notification Description is required!");
    } else {
      setDetailError("");
    }
    setDetail(val);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    if (name.trim() === "") {
      setNameError("Notification Name is required!");
      valid = false;
     
    } else {
      setNameError("");
    }
    if (detail.trim() === "") {
      setDetailError("Notification Description is required!");
      valid = false;
    
    } else {
      setDetailError("");
    }
    if (!valid) {
      setError("");
      return;
    } else {
      setError("");
      try {
        props.application.setLoading(true);
        if (id === "") { 
          const data = {
            name: name.trim(),
            description: detail.trim(),
          };
          const responce = await props.admin.addNotification(data);
          if (responce.status === 200) {
            
            toast.success("Notification sent to all users successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
            props.application.setLoading(false);
          } else {
            toast.error("Something went wrong!", {
              position: toast.POSITION.TOP_CENTER,
            });
            props.application.setLoading(false);
          }
        } else {
          const data = {
            name: name.trim(),
            description: detail.trim(),
            id: id,
          };
          const responce = await props.admin.updateNotification(data);
          if (responce.status === 200) {
            props.application.setLoading(false);
            toast.success("Notification updated successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.error("Something went wrong!", {
              position: toast.POSITION.TOP_CENTER,
            });
            props.application.setLoading(false);
          }
        }
        handleClose();
        setId("");
        getData();
      } catch (err) {
        props.application.setLoading(false);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_CENTER,
        });
        setId("");
        console.log(err);
      }
    }
  };

  const handleEdit = async (id) => {
    try {
      props.application.setLoading(true);

      const data = {
        id: id,
      };
      const res = await props.admin.getNotificationById(data);
      setName(res.notification.name);
      setDetail(res.notification.description);
      setId(id);
      handleShow();
      props.application.setLoading(false);
    } catch (error) {
      props.application.setLoading(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Confirmation",
      text: "Are you sure you want to delete this Notifications?",
      showCancelButton: true,
      confirmButtonColor: "#3f6dcf",
      cancelButtonColor: "#373737",
      confirmButtonText: `Yes`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const data = {
            id: id,
          };
          props.application.setLoading(true);

          const res = await props.admin.deleteNotificationById(data);
          if (res.success === true) {
          
            const filteredDataCopy = [...filteredData];
            const index = filteredDataCopy.findIndex((item) => item._id === id);
            if (index !== -1) {
              filteredDataCopy.splice(index, 1);
            }
            setFilteredData(filteredDataCopy);
            const pageCount = Math.ceil(filteredDataCopy.length / rowsPerPage);
            if (currentPage >= pageCount) {
              setCurrentPage(pageCount - 1);
            }
            props.application.setLoading(false);
            toast.success("Notification is deleted!", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            Swal.fire("Notification!", "Something went wrong!", "error");
          }
        } catch (err) {
          props.application.setLoading(false);
          console.log(err);
          Swal.fire("Notification!", "Something went wrong!", "error");
          return Promise.reject(err);
        }
      }
    });
  };

  const calculateSNo = (index) => {
    return currentPage * rowsPerPage + index + 1;
  };

  const rowsPerPage = 10; // Number of rows to display per page
  const offset = currentPage * rowsPerPage; // Calculate the offset based on current page
  const paginatedData = filteredData.slice(offset, offset + rowsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);

    // Check if it's the first page
    if (selected === 0) {
      setShowPreviousButton(false);
    } else {
      setShowPreviousButton(true);
    }

    // Check if it's the last page
    const pageCount = Math.ceil(filteredData.length / rowsPerPage);
    if (selected === pageCount - 1 || pageCount === 1) {
      // Update the condition here
      setShowNextButton(false);
    } else {
      setShowNextButton(true);
    }
  };

  const renderPagination = () => {
    const pageCount = Math.ceil(filteredData.length / rowsPerPage);
    if (pageCount > 1) {
      return (
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
          forcePage={currentPage}
          containerClassName="pagination"
          activeClassName="active"
          previousLabel={showPreviousButton ? "Previous" : null}
          nextLabel={showNextButton ? "Next" : null}
          breakLabel="..."
          disableInitialCallback={true}
        />
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={routes.DASHBOARD}>Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Notifications</li>
          </ol>
        </nav>
      </div>
      {/* {isDataLength === false ? <div className="verifier-buttton">
     <Button onClick={handleAdd}>Add Privacy Policy</Button>{" "}
   </div> : "" } */}
      <div className="verifier-buttton">
        <Button onClick={handleAdd}>Add Notification</Button>{" "}
      </div>
      <Modal show={showModal} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton className="modal-main-header">
          <Modal.Title>
            {id === "" ? "Add" : "Update"} Notification
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="searchformfld">
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Notification Name*"
                value={name}
                onChange={handleName}
                required
              />
              {nameError && <span className="error">{nameError}</span>}
              <textarea
                className="form-control mb-3"
                placeholder="Notification Description*"
                rows={5}
                cols={5}
                value={detail}
                onChange={handleDetail}
              />
             
            </div>
            {detailError && <span className="error">{detailError}</span>}
          </form>
          <div className="d-flex align-items-center justify-content-center"></div>
        </Modal.Body>
        {error && (
          <div className="col-xs-12 col-md-12 col-lg-12">
            <div className="alert alert-danger">{error}</div>
          </div>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            type="submit"
            variant="primary custom-button-colour"
            onClick={handleSubmit}
          >
            {id === "" ? "Save Changes" : "Update Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <table id="about" className="table table-bordered table-striped">*/}
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Notification Name</th>
            <th>Notification Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData && paginatedData.length > 0 ? (
            <React.Fragment>
              {paginatedData.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>{calculateSNo(idx)}</td>
                    <td>{item.name}</td>
                    <td>{item.description}</td>
                    <td>
                      <div className="delete-create">
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            handleEdit(item._id);
                          }}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </Link>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            handleDelete(item._id);
                          }}
                        >
                          <i className="fa-sharp fa-solid fa-trash"></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </React.Fragment>
          ) : (
            <tr>
              <td colSpan="10 mt-2">No results found!</td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="table-footer">
        <strong>Total Count: {filteredData.length}</strong>
        {renderPagination()}
      </div>
    </React.Fragment>
  );
}

export default injectModels(["admin", "application"])(Notification);
