import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { injectModels } from "../../redux/injectModels";
import { Link } from "react-router-dom";
import { Routes } from "../../constants";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [passError, setPassError] = useState("");
  const [emailError, setEmailError] = useState("");
  // const [passwordType, setPasswordType] = useState("password");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let email = localStorage.getItem("RememberMeEmail");
    let password = localStorage.getItem("Password");
    const id = localStorage.getItem("UserId");
    if (email != null && password != null) {
      setEmail(email);
      setPassword(password);
      setRememberMe(true);
    }
    if (id != null) {
      navigate(Routes.DASHBOARD);
    } else {
      navigate(Routes.DEFAULT);
    }
  }, []);

  const handleEmailChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();
    if (val === "") {
      setEmailError("Email is required");
    } else if (!emailRegex.test(val)) {
      setEmailError("Please enter the correct Email!");
    } else {
      setEmailError("");
    }

    setEmail(val);
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setPassError("Password is required");
    } else {
      setPassError("");
    }
    setPassword(val);
  };


  const togglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    props.application.setLoading(true, "Login!");

    let valid = true;

    if (password === "") {
      setPassError("Password is required");
      valid = false;
    } else {
      setPassError("");
    }

    if (email.trim() === "") {
      setEmailError("Email is required");
      valid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter the correct Email!");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!valid) {
      props.application.setLoading(false, "");
      return;
    } else {
      const data = {
        email: email.trim(),
        password: password,
      };
      try {
        props.application.setLoading(true);
        const res = await props.auth.userLogin(data);
        if (res.success) {
          props.application.setLoading(false);
          if (rememberMe) {
            localStorage.setItem("RememberMeEmail", email);
            localStorage.setItem("Password", password);
          } else {
            localStorage.removeItem("RememberMeEmail");
            localStorage.removeItem("Password");
          }
          navigate(Routes.DASHBOARD);
          toast.success("Logged in successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setError("");
        } else {
          props.application.setLoading(false);
          setError(res.message);
          localStorage.clear();
          return;
        }
      } catch (err) {
        props.application.setLoading(false);
        console.log(err);
        setError(err.message);
      }
    }
  };

  return (
    <React.Fragment>
      <section className="login-page section register py-3">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-8  d-flex flex-column align-items-center justify-content-center">
              <div className="d-flex justify-content-center pb-3">
                <Link className="logo d-flex align-items-center w-auto" to="/">
                  <img src={`/assets/img/nakamoto-logo.png`} alt="" />
                </Link>
              </div>
              <div className="card mb-3">
                <div className="card-body">
                  <div className="pt-1 pb-3">
                    <h5 className="card-title text-center pb-0 fs-4">
                      Admin Login
                    </h5>
                  </div>
                  <form
                    className="row g-2 needs-validation account-login"
                    onSubmit={handleSubmit}
                  >
                    <div className="col-12">
                      <label htmlFor="yourUsername" className="form-label">
                        Email<span className="aestrick">*</span>
                      </label>
                      <div className="input-group has-validation">
                        <span
                          className="input-group-text"
                          id="inputGroupPrepend"
                        >
                          <i className="fa-regular fa-envelope"></i>{" "}
                        </span>
                        <input
                          type="text"
                          name="username"
                          className="form-control"
                          id="yourUsername"
                          placeholder="Email*"
                          autoComplete="off"
                          value={email}
                          onChange={handleEmailChange}
                        />
                      </div>
                      {emailError && (
                        <span className="error">{emailError}</span>
                      )}
                    </div>
                    <div className="col-12">
                      <label htmlFor="yourPassword" className="form-label">
                        Password<span className="aestrick">*</span>
                      </label>
                      <div className="input-group has-validation">
                        <span
                          className="input-group-text"
                          id="inputGroupPrepend"
                        >
                          <i className="fa-solid fa-lock"></i>
                        </span>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          name="password"
                          id="password"
                          placeholder="Password*"
                          value={password}
                          onChange={handlePasswordChange}
                        />

                        <span
                          className="btn btn-outline-secondary eye-splash"
                          id="toggle"
                          onClick={togglePassword}
                        >
                          {showPassword ? (
                            <i className="fa-solid fa-eye"></i>
                          ) : (
                            <i className="fa-solid fa-eye-slash"></i>
                          )}
                        </span>
                      </div>
                      {passError && <span className="error">{passError}</span>}
                    </div>
                    <div className="col-12">
                      <div className="remMe">
                        <div className="form-check"></div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="rememberMe"
                            id="rememberMe"
                            checked={rememberMe}
                            onChange={handleRememberMeChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="rememberMe"
                          >
                            Remember Me{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                    {error && (
                      <div className="col-12">
                        <div className="alert alert-danger">{error}</div>
                      </div>
                    )}
                    <div className="col-12 login">
                      <button type="submit" className="btn  w-100">
                        Login
                      </button>
                    </div>
                    <div className="col-12 text-center create-account">
                      <p className="small mb-0">
                        Forgot your password?
                        <Link to={Routes.FORGOTPASSWORD} className="ac-new">
                          {" "}
                          <span>Click here to reset it</span>
                        </Link>
                        {/* <a href="javscript" className="ac-new">Click here to reset it</a> */}
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default injectModels(["application", "auth"])(Login);
