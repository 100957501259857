import React, { useState, useEffect } from "react";
import * as CONTRACTS from "../../Contract";
import { injectModels } from "../../redux/injectModels";
import Web3 from "web3";
import { Modal } from "react-bootstrap";

const RenderElement = (props) => {
  // console.log(props,"props");
  const [userData, setUserData] = useState([]);
  const ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;

  const [isLoading, setIsLoading] = useState(false);
  const [sponsors, setSponsors] = useState([]);
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [userDetailsFromContract, setUserDetailsFromContract] = useState("");
  const [isUserActive, setIsUserActive] = useState(false);

  const getData = async (walletAddress) => {
    props.application.setLoading(true);
    try {
      const res = await props.admin.getUserProfileDetails(walletAddress);

      if (res.data) {
        props.application.setLoading(false);
        return res.data;
      } else {
        props.application.setLoading(false);
      }
    } catch (error) {
      props.application.setLoading(false);
      console.log(error);
    }
  };

  const handleCloseTopUpModal = () => {
    setShowTopUpModal(false);
  };

  useEffect(() => {
    getUserSponsors(props.userAddress);
  }, [props.application, props.admin]);

  const getUserSponsors = async (userAddress) => {
    try {
      setIsLoading(true);
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
      const web3 = new Web3(provider);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

      const receipt = await contract.methods.getUser(userAddress).call();
      setUserDetailsFromContract(receipt);
      setSponsors(receipt.sponsors);
      setIsLoading(false);

      const res = await contract.methods.getUserStatus(userAddress).call();
      setIsUserActive(res && res.isUserActive_);
      return receipt.sponsors;
    } catch (exception) {
      setIsLoading(false);
      setSponsors([]);
      console.log(exception);
      return [];
    }
  };

  const updateOpenElementList = async () => {
    try {
      setIsLoading(true);
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
      const web3 = new Web3(provider);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
      let currentUpline;
      let usersAddresRequired = props.userAddress;
      let newOpenElementList = [];
      newOpenElementList.push(props.userAddress);
      const receipt = await contract.methods
        .getUser(usersAddresRequired)
        .call();

      if (receipt.uplineID === "0x0000000000000000000000000000000000000000") {
        currentUpline = props.userAddress;
      } else {
        currentUpline = receipt.uplineID;
        newOpenElementList.push(currentUpline);
      }

      if (receipt.uplineID !== "0x0000000000000000000000000000000000000000") {
        while (props.parentAddress !== currentUpline) {
          const receipt = await contract.methods
            .getUser(usersAddresRequired)
            .call();
          usersAddresRequired = currentUpline;
          if (
            receipt.uplineID === "0x0000000000000000000000000000000000000000"
          ) {
            currentUpline = props.userAddress;
          } else {
            currentUpline = receipt.uplineID;
            newOpenElementList.push(currentUpline);
          }
        }
      }
      // console.log(newOpenElementList);
      props.setOpenElementList(newOpenElementList);
      setIsLoading(false);
    } catch (exception) {
      console.log(exception);
      setIsLoading(false);
    }
  };

  const handleShow = async (userAddress) => {
    setShowTopUpModal(true);
    const data = await getData(userAddress);

    if (data && data.data.user) {
      setUserData(data.data.user);
    }
  };

  const convertTimestampToDateTime = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    let hours = date.getUTCHours();
    const minutes = `0${date.getUTCMinutes()}`.slice(-2);
    const seconds = `0${date.getUTCSeconds()}`.slice(-2);

    let period = "AM";
    if (hours >= 12) {
      period = "PM";
      hours -= 12;
    }
    if (hours === 0) {
      hours = 12;
    }

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${period}`;

    return formattedDateTime;
  };

  const calculateDaysLeft = (timestamp, daysThreshold) => {
    const now = new Date();
    const lastActiveDate = new Date(timestamp * 1000);
    const msInDay = 1000 * 60 * 60 * 24;
    const daysPassed = Math.floor((now - lastActiveDate) / msInDay);
    const daysLeft = daysThreshold - daysPassed;

    return {
      daysLeft: Math.abs(daysLeft),
      isOverdue: daysLeft < 0,
    };
  };

  const daysLeftForHoldData = calculateDaysLeft(
    userDetailsFromContract.lastActiveTimestamp,
    90
  );
  const daysLeftForDeactivationData = calculateDaysLeft(
    userDetailsFromContract.lastActiveTimestamp,
    180
  );

  console.log(userDetailsFromContract, "userDetailsFromcontract");

  return (
    <>
      <div className="row">
        {props.userAddress !== props.parentAddress && (
          <div
            className="col-12 d-flex justify-content-center"
            style={{ color: "#cacaca" }}
          >
            ↓
          </div>
        )}
        <div className="col-12 d-flex justify-content-center">
          <div
            className="user-network"
            onClick={(userAddress) => {
              if (!isLoading) {
                updateOpenElementList();
              } else {
                console.log("please wait");
              }
            }}
          >
            <div
              onClick={() => handleShow(props.userAddress)}
              className="expand-icon"
            >
              <i className="fa-solid fa-up-right-and-down-left-from-center"></i>
            </div>
            <img
              src="https://api.nakatoken.io/images/8efd7c6199a0e0cba4428e774778f377.1707123104916.png"
              alt=""
              className="img-fluid"
            />
            {props.userAddress.slice(-7)}

            {isLoading && <div style={{ color: "white" }}>fetching..</div>}
            <div>{sponsors.length}</div>
            {props.openElementList.find(
              (value) => value === props.userAddress
            ) ? (
              // <div>Open</div>
              <i class="fa-solid fa-angle-up"></i>
            ) : (
              <i class="fa-solid fa-angle-down"></i>
              // <div>Not Open</div>
            )}
          </div>
        </div>
        {props.openElementList.find((value) => value === props.userAddress) && (
          <div
            className=" col-12 d-flex justify-content-center"
            style={{ color: "#cacaca" }}
          >
            ↓
          </div>
        )}
        {props.openElementList.find((value) => value === props.userAddress) &&
          sponsors.length > 0 && (
            <div className=" col-12 d-flex justify-content-left">
              <div
                style={{
                  borderTopWidth: "2px",
                  borderTopColor: "#15aeab",
                  borderTopStyle: "solid",
                }}
              >
                {sponsors.map((value) => (
                  <div
                    style={{
                      display: "inline-block",
                      marginRight: "10px",
                      verticalAlign: "top",
                    }}
                    key={value}
                  >
                    <RenderElement
                      userAddress={value}
                      openElementList={props.openElementList}
                      setOpenElementList={props.setOpenElementList}
                      parentAddress={props.parentAddress}
                      application={props.application}
                      admin={props.admin}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
      </div>

      <Modal backdrop="static" show={showTopUpModal} centered>
        <div className="networkexpand">
          <Modal.Body>
            <div className="my-custom-class">
              <div className="user-business-details">
                <div className="busines-user-img">
                  <div className="busines-user-img-content">
                    <div class="ribbon-2">{userDetailsFromContract.rank}</div>
                    <img
                      src={
                        userData
                          ? userData.avatar
                          : "https://api.nakatoken.io/images/8efd7c6199a0e0cba4428e774778f377.1707123104916.png"
                      }
                      alt=""
                      className="img-fluid"
                    />
                    <div className="acti-deca">
                      {isUserActive ? (
                        <button className="green">Active</button>
                      ) : (
                        <button className="red">Deactive</button>
                      )}
                    </div>
                    <div onClick={handleCloseTopUpModal} className="cros-icon">
                      {" "}
                      <i class="fa-solid fa-xmark"></i>{" "}
                    </div>
                  </div>
                  <div className="busines-user-img-text">
                    <h6>{userData ? userData.firstName : "Naka"}</h6>
                    <h6>{userData ? userData.userId : "*******"}</h6>
                    <h6>
                      {userData
                        ? userData.walletAddress
                        : "@@@@@@@@@@@@@@@@@@@@@@@"}
                    </h6>
                  </div>
                </div>

                <div className="bns-details">
                  <ul>
                    <li>
                      <p>Total Business :</p>
                      <p>
                        {Number(
                          userDetailsFromContract.businessWithTeam /
                            Math.pow(10, 18)
                        )}{" "}
                        USDT
                      </p>{" "}
                    </li>
                    <li>
                      <p>Last Worked On :</p>{" "}
                      <p>
                        {convertTimestampToDateTime(
                          userDetailsFromContract.lastActiveTimestamp
                        )}
                      </p>{" "}
                    </li>
                    <li>
                      <p>Joining Date :</p>{" "}
                      <p>
                        {convertTimestampToDateTime(
                          userDetailsFromContract.joiningTimestamp
                        )}
                      </p>{" "}
                    </li>
                    <li>
                      <p>Total Investment :</p>{" "}
                      <p>
                        {" "}
                        {Number(
                          userDetailsFromContract.totalInvestment /
                            Math.pow(10, 18)
                        )}{" "}
                        USDT
                      </p>
                    </li>
                    {/* <li>
                      <p>Status :</p>
                      <p>{`Hold after ${daysLeftForHold} days`}</p>
                      <p>{`Deactivate after ${daysLeftForDeactivation} days`}</p>
                    </li> */}
                    {/* <li>
                          <p>Rank :</p>
                          <p>{userDetailsFromContract.rank}</p>
                        </li> */}
                  </ul>
                  <div className="info-alert">
                    {daysLeftForHoldData.isOverdue ? (
                      <div className="alert alert-warning" role="alert">
                        {`Already on hold for ${daysLeftForHoldData.daysLeft} days`}
                      </div>
                    ) : (
                      <div className="alert alert-warning" role="alert">
                        {`Hold after ${daysLeftForHoldData.daysLeft} days`}
                      </div>
                    )}
                    {daysLeftForDeactivationData.isOverdue ? (
                      <div className="alert alert-danger" role="alert">
                        {`Already deactivated for ${daysLeftForDeactivationData.daysLeft} days`}
                      </div>
                    ) : (
                      <div className="alert alert-danger" role="alert">
                        {`Deactivate after ${daysLeftForDeactivationData.daysLeft} days`}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>{" "}
      </Modal>
    </>
  );
};

export default injectModels(["admin", "application"])(RenderElement);
